import * as eventsApi from "api/models/events/eventsApi";
import {
    DuplicatedDeviceEventsResponse,
    EventBatchDetailsModel,
    RsbAdjustmentForRange,
} from "api/models/events/eventsApi";
import { ApiValidationError } from "api/models/sharedModels/ApiValidationError";
import { AuthModel } from "api/models/sharedModels/AuthModel";
import { BaseResponse } from "api/models/sharedModels/ResponseModels";
import { services } from "api/serviceConfig";
import { AxiosResponse } from "axios";
import { newOrNullIfSame } from "components/StabilityForm/helper-functions";
import dayjs from "dayjs";
import { FormikErrors } from "formik";
import { areAllFieldsNullDeepCheck } from "helpers/areAllFieldsNullDeepCheck";
import { dateToFormat } from "helpers/dateFormattingHelper";
import { extractFileNameFromContentDisHeader, forceFileDownload } from "helpers/fileHelpers";
import { getColorByIndex } from "helpers/getColorByIndex";
import { dateInUTC } from "helpers/timezoneHelper";
import _ from "lodash";
import { commonConstants } from "lynxConstants";
import { makeAutoObservable } from "mobx";
import { RangeOperatorFrom, RangeOperatorTo } from "models/productAndStabilityForm/productAndStabilityFormModels";
import { localStorageService } from "helpers/localStorageService";
import { PaginationArea } from "models/shared/Page";
import * as eventModel from "models/thorEvents/eventModels";
import * as usersModels from "models/userManagement/userManagementModels";
import CommonStore from "./CommonStore";
import IdentityStore from "./IdentityStore";
import { SearchParameter } from "components/LynxComponents/LynxSearch";

export class ThorEventViewStore {
    constructor(identityStore: IdentityStore, commonStore: CommonStore) {
        makeAutoObservable(this);
        this.identityStore = identityStore;
        this.commonStore = commonStore;

        this.manualEventModelReadOnly = this.manualEventModelBlankValue;
    }

    identityStore: IdentityStore;
    commonStore: CommonStore;
    activeEvents: eventModel.EventListItem[] = [];
    currentEventPage = 1;
    totalEventPages = 1;
    totalCount = 0;
    eventPageSize = localStorageService.getPageSize(PaginationArea.Events) ?? 10;

    currentBatchHistoryPage = 1;
    totalBatchHistoryPages = 1;
    totalBatchHistoryCount = 0;
    batchHistoryRelevantEventsCount = 0;
    batchHistoryPageSize = localStorageService.getPageSize(PaginationArea.BatchHistory) ?? 10;
    batchHistoryGetRelevantEventsOnly = true;

    // TODO: Separate sorting and filtering to other store?
    sortingDirection: "ASC" | "DESC" = "DESC";
    sortingValue: string = "DisplayId";

    dueDateSelectedFilters: string[] = [];
    evaluationStatusSelectedFilters: string[] = [];
    prioritySelectedFilters: string[] = [];
    assignedUsersSelectedFilters: string[] = [];
    eventCodeSelectedFilters: string[] = [];

    // search
    searchValue: string = "";

    searchParameter: SearchParameter<"event"> = "DisplayId";

    get searchInputTrimmed() {
        return this.searchValue.trim();
    }

    setSearchParameter = (value: SearchParameter<"event">) => {
        this.searchParameter = value;
    };

    setSearchValue = (value: string) => {
        this.searchValue = value;
    };

    setDueDateFilter = (value: string[]) => {
        this.dueDateSelectedFilters = value.filter((x) => x);
    };

    setEventCodeFilter = (value: string[]) => {
        this.eventCodeSelectedFilters = value.filter((x) => x);
    };

    setEvaluationStatusFilter = (value: string[]) => {
        this.evaluationStatusSelectedFilters = value.filter((x) => x);
    };

    setPriorityFilter = (value: string[]) => {
        this.prioritySelectedFilters = value.filter((x) => x);
    };

    setAssignedUsersFilter = (value: string[]) => {
        this.assignedUsersSelectedFilters = value.filter((x) => x);
    };

    resetAllFiltersAndSearch = () => {
        this.dueDateSelectedFilters = [];
        this.evaluationStatusSelectedFilters = [];
        this.prioritySelectedFilters = [];
        this.assignedUsersSelectedFilters = [];
        this.eventCodeSelectedFilters = [];
    };

    isAssignMenuOpen = false;

    isFlowChanged = false;

    eventDetails: eventModel.EventDetails = {
        id: "",
        displayId: 0,
        type: eventModel.EventType.Transportation,
        status: eventModel.EventStatus.New,
        processType: eventModel.EventProcessType.Commercial,
        excursionSource: eventModel.ExcursionSource.TemperatureRecordingDevice,
        createdAt: "",
        createdBy: "",
        closedAt: null,
        eventCodeIds: [],
        priorityId: null,
        timezone: commonConstants.UTCTimezone,
        dueDate: null,
        quarantineDate: null,
        reviewerUserId: null,
        reviewerFirstName: null,
        reviewerLastName: null,
        qaUserId: null,
        qaFirstName: null,
        qaLastName: null,
        minTemperature: 0,
        maxTemperature: 0,
        totalExcursionDuration: 0,
        totalLowExcursionDuration: null,
        totalHighExcursionDuration: null,
        conditioningRight: null,
        conditioningLeft: null,
        conditioningAppliedDt: null,
        conditioningAppliedBy: null,
        conditioningAppliedByFirstName: null,
        conditioningAppliedByLastName: null,
        conditioningNote: null,
        temperatureLowerLimit: null,
        temperatureLowerLimitOperator: null,
        temperatureUpperLimit: null,
        temperatureUpperLimitOperator: null,
        temperatureActualLowerLimit: null,
        temperatureActualUpperLimit: null,
        hasTransportationConditions: false,
        manualExcursionDeviceIds: null,
        excursionSegments: [],
        assessments: [],
        handlingUnits: [],
        openedPreviousEvents: [],
        deliveryNumber: null,
        orderNumbers: null,
        shippingDate: null,
        deliveryDate: null,
        origin: {
            id: null,
            code: null,
            name: null,
            addressLine1: null,
            addressLine2: null,
            city: null,
            country: null,
            postalCode: null,
            region: null,
            site: null,
            state: null,
        },
        destination: {
            id: null,
            code: null,
            name: null,
            addressLine1: null,
            addressLine2: null,
            city: null,
            country: null,
            postalCode: null,
            region: null,
            site: null,
            state: null,
        },
        site: {
            id: null,
            code: null,
            name: null,
            addressLine1: null,
            addressLine2: null,
            city: null,
            country: null,
            postalCode: null,
            region: null,
            site: null,
            state: null,
        },
        shipperType: null,
        transportationModeName: null,
        transportationServiceProviderName: null,
        logisticsServiceProviderName: null,
        laneNumber: null,
        laneStatus: null,
    };

    // Event Attachments
    attachments: eventsApi.EventAttachmentWithFullNameResponse[] = [];
    attachmentsTotalCount: number = 0;
    attachmentsFirstSetLoadingCount: number = 0;
    attachmentPageNumber = 1;

    isFileAttachModalOpen = false;
    isFileViewModalOpen = false;

    selectedDevise: string = "";

    devicesData: eventModel.DeviceData[] = [];

    editEventInfo: eventsApi.GetEditEventInfoResponse = {
        customerId: "",
        eventId: "",
        devices: [],
        products: [],
        handlingUnits: [],
    };

    editEventModel: eventModel.EditEventModel = {
        handlingUnits: [
            {
                businessKey: "",
                deviceIds: [],
                products: [
                    {
                        productId: "",
                        batchId: "",
                        quantity: "",
                    },
                ],
            },
        ],
    };

    formErrors: FormikErrors<eventModel.EditEventModel> = {};
    setEditEventErrors = (errors: FormikErrors<eventModel.EditEventModel>) => (this.formErrors = errors);

    get manualEventModelBlankValue(): eventModel.ManualEventModel {
        return {
            id: "",
            displayId: 0,
            type: eventModel.EventType.Transportation,
            excursionSource: eventModel.ExcursionSource.TemperatureRecordingDevice,
            processType: eventModel.EventProcessType.Commercial,
            quarantineDate: null,
            timezone: "",
            deliveryInformation: {
                id: "",
                deliveryNumber: "",
                orderNumbers: "",
                laneNumber: "",
                shipperType: "",
                transportationModeId: commonConstants.emptyGuid,
                transportationServiceProviderName: "",
                logisticsServiceProviderName: "",
                transportationLowerLimit: "",
                transportationLowerLimitOperator: RangeOperatorFrom.GREATER_THAN_OR_EQUAL,
                transportationUpperLimit: "",
                transportationUpperLimitOperator: RangeOperatorTo.LESS_THAN_OR_EQUAL,
                origin: {
                    id: "",
                    code: "",
                    name: "",
                },
                destination: {
                    id: "",
                    code: "",
                    name: "",
                },
            },
            site: {
                id: "",
                code: "",
                name: "",
            },
            batches: [],
            devices: [],
            manualExcursion: {
                lowTemperature: "",
                highTemperature: "",
                startDateTime: "",
                endDateTime: "",
            },
            manualExcursionDeviceIds: "",
            eventAttachments: [] as eventsApi.AttachmentDataWithFullName[],
            comment: "",
        };
    }

    manualEventModelReadOnly: eventModel.ManualEventModel;

    createEventErrors: FormikErrors<eventModel.ManualEventModel> = {};
    setCreateEventErrors = (errors: FormikErrors<eventModel.ManualEventModel>) => (this.createEventErrors = errors);

    calculatedExcursion: eventModel.CalculatedExcursion = {
        minTemperature: null,
        maxTemperature: null,
        totalDuration: null,
        totalLowDuration: null,
        totalHighDuration: null,
        startedAt: null,
        endedAt: null,
    };

    devicesExcursion: Array<eventsApi.ExcursionResultPerDevice> = [];

    progressFlags = {
        loadingEvents: false,
        loadingEventShortInfo: false,
        loadingEventDetails: false,
        makingProductDecision: false,
        loadingBatchHistory: false,
        exportingPdf: false,
        loadingProductFlows: false,
        selectingProductFlow: false,
        settingPriority: false,
        settingCodes: false,
        settingTimezone: false,
        assigningUserToEvent: false,
        settingCompleteReview: false,
        settingQuantity: false,
        settingEventType: false,
        loadingDeviceData: false,
        settingDueDate: false,
        settingShippingDate: false,
        settingDeliveryDate: false,
        settingQuarantineDate: false,
        settingEventProcessType: false,
        isConditioningInProgress: false,
        isTransportationTemperatureChangeInProgress: false,
        loadingPriorities: false,
        loadingAttachments: false,
        uploadingAttachments: false,
        loadingNextSetOfAttachments: false,
        loadingEditEventInfo: false,
        editingEvent: false,
        createEvent: false,
        loadManualEventInformation: false,
        checkingForDuplicatedDevices: false,
    };

    get isLastEventPage() {
        return this.currentEventPage === this.totalEventPages;
    }

    setCalculatedExcursion = (excursion: eventModel.CalculatedExcursion) => {
        this.calculatedExcursion = excursion;
    };

    resetCalculatedExcursion = () => {
        this.calculatedExcursion.minTemperature = null;
        this.calculatedExcursion.maxTemperature = null;
        this.calculatedExcursion.startedAt = null;
        this.calculatedExcursion.endedAt = null;
        this.calculatedExcursion.totalDuration = null;
    };

    setEventPage = (page: number) => {
        this.currentEventPage = page;
    };

    setEventsPageSize = (pageSize: number) => {
        this.eventPageSize = pageSize;
    };

    moveToEventPage = (direction: "back" | "forward") => {
        direction === "back" ? this.currentEventPage-- : this.currentEventPage++;
    };

    clearEventPagination = () => {
        this.activeEvents = [];
        this.currentEventPage = 1;
        this.totalEventPages = 1;
    };

    get isLastBatchHistoryPage() {
        return this.currentBatchHistoryPage === this.totalBatchHistoryPages;
    }

    setBatchHistoryPage = (page: number) => {
        this.currentBatchHistoryPage = page;
    };

    setBatchHistoryPageSize = (pageSize: number) => {
        this.batchHistoryPageSize = pageSize;
    };

    moveToBatchHistoryPage = (direction: "back" | "forward") => {
        direction === "back" ? this.currentBatchHistoryPage-- : this.currentBatchHistoryPage++;
    };

    clearBatchHistoryPagination = () => {
        this.currentBatchHistoryPage = 1;
        this.totalBatchHistoryPages = 1;
        this.totalBatchHistoryCount = 0;
        this.batchHistoryRelevantEventsCount = 0;
        this.batchHistoryGetRelevantEventsOnly = true;
    };

    setShowRelevantEventsOnly = (relevantEventsOnly: boolean) => {
        this.batchHistoryGetRelevantEventsOnly = relevantEventsOnly;
    };

    selectedAssessment: string = "";

    setSelectedAssessment = (assessmentId: string) => {
        this.selectedAssessment = assessmentId;
    };

    setSortingDirection = (direction: "ASC" | "DESC") => {
        this.sortingDirection = direction;
    };

    setSortingValue = (sortingValue: string) => {
        this.sortingValue = sortingValue;
    };

    setSelectedDevise = (label: string) => {
        this.selectedDevise = label;
    };

    setFileAttachModalOpen = (open: boolean) => {
        this.isFileAttachModalOpen = open;
    };

    setFileViewModalOpen = (open: boolean) => {
        this.isFileViewModalOpen = open;
    };

    setAssignMenuOpen = (isOpen: boolean) => {
        this.isAssignMenuOpen = isOpen;
    };

    incrementAttachmentsFirstSetCount = () => {
        this.attachmentsFirstSetLoadingCount++;
    };

    setAttachmentPageNumber = (value: number) => {
        this.attachmentPageNumber = value;
    };

    setUploadingAttachmentsFlag = (value: boolean) => {
        this.progressFlags.uploadingAttachments = value;
    };

    setLoadingAttachmentsFlag = (value: boolean) => {
        this.progressFlags.loadingAttachments = value;
    };

    loadEvents = async (request: eventsApi.ListEventsRequest) => {
        this.progressFlags.loadingEvents = true;

        try {
            const response = await services.Events.listEvents(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.activeEvents = response.data.items;
            this.totalEventPages = response.data.totalPages;
            this.totalCount = response.data.totalCount;

            if (this.currentEventPage > response.data.totalPages && response.data.totalPages !== 0) {
                this.currentEventPage = response.data.totalPages;
            }
        } finally {
            this.progressFlags.loadingEvents = false;
        }
    };

    loadEventDetails = async (request: eventsApi.GetEventDetailsRequest) => {
        this.progressFlags.loadingEventDetails = true;

        try {
            const response = await services.Events.getEventDetails(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.eventDetails = response.data;
        } finally {
            this.progressFlags.loadingEventDetails = false;
        }
    };

    makeProductDecision = async (
        request: eventsApi.MakeProductDecisionRequest,
        assessment: eventModel.AssessmentModel
    ) => {
        let response;
        this.progressFlags.makingProductDecision = true;

        try {
            response = await services.Events.makeProductDecision(request);

            if (!_.inRange(response.status, 200, 300)) return;

            assessment.decision = request.assessmentDecision;
            assessment.qaUserId = this.identityStore.currentUser.id;
            assessment.qaFirstName = this.identityStore.currentUser.firstName;
            assessment.qaLastName = this.identityStore.currentUser.lastName;
            assessment.completedDate = dayjs().toDate();
            assessment.justification = request.justification;
            assessment.decision = request.assessmentDecision;
            assessment.status = eventModel.AssessmentStatus.EvaluationCompleted;

            if (
                this.eventDetails.assessments.every((x) => x.status === eventModel.AssessmentStatus.EvaluationCompleted)
            ) {
                this.eventDetails.closedAt = dayjs().toDate();
                this.eventDetails.status = eventModel.EventStatus.Closed;
            }

            this.commonStore.incrementCommentLoadingCountToForceReload();
        } finally {
            request.callback(response ? response.status : 0);
            this.progressFlags.makingProductDecision = false;
        }
    };

    generatePdf = async (customerId: string, eventId: string) => {
        const currentTimezone = dayjs.tz.guess();
        const eventTimezone = this.eventDetails.timezone;

        const request: eventsApi.GetPdfSummaryRequest = {
            customerId,
            eventId,
            currentTimezone,
            eventTimezone,
        };

        this.progressFlags.exportingPdf = true;

        try {
            const response = await services.Events.generatePdf(request);

            if (!_.inRange(response.status, 200, 300)) return;

            const fileName = extractFileNameFromContentDisHeader(response);

            forceFileDownload(fileName, response.data);
        } finally {
            this.progressFlags.exportingPdf = false;
        }
    };

    getProductFlows = async (request: eventsApi.GetProductFlowsRequest) => {
        this.progressFlags.loadingProductFlows = true;

        try {
            const response = await services.Events.getProductFlows(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.eventDetails.assessments
                .filter((x) => x.productId === request.productId)
                .forEach((x) => {
                    x.flows = response.data;
                });
        } finally {
            this.progressFlags.loadingProductFlows = false;
        }
    };

    setFlowForAssessment = async (request: eventsApi.SetFlowForAssessmentRequest) => {
        this.progressFlags.selectingProductFlow = true;

        try {
            const response = await services.Events.setFlowForAssessment(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.isFlowChanged = true;
            this.setSelectedAssessment("");
        } finally {
            this.progressFlags.selectingProductFlow = false;
            this.isFlowChanged = false;
        }
    };

    changeDeliveryTemperatureRange = async (request: eventsApi.ChangeDeliveryTemperatureRangeRequest) => {
        this.progressFlags.isTransportationTemperatureChangeInProgress = true;

        try {
            await services.Events.changeDeliveryTemperatureRange(request);
        } finally {
            this.progressFlags.isTransportationTemperatureChangeInProgress = false;
        }
    };

    setEventCodes = async (request: eventsApi.SetEventCodesRequest) => {
        this.progressFlags.settingCodes = true;

        try {
            const response = await services.Events.setEventCodes(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.eventCodeIds = request.eventCodesIds;
        } finally {
            this.progressFlags.settingCodes = false;
        }
    };

    setEventPriority = async (request: eventsApi.SetEventPriorityRequest) => {
        this.progressFlags.settingPriority = true;

        try {
            const response = await services.Events.setEventPriority(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.priorityId = request.priorityId;
            this.eventDetails.dueDate = response.data;
        } finally {
            this.progressFlags.settingPriority = false;
        }
    };

    setEventTimezone = async (request: eventsApi.SetEventTimezoneRequest) => {
        this.progressFlags.settingTimezone = true;

        try {
            const response = await services.Events.setEventTimezone(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.timezone = request.timezone;
        } finally {
            this.progressFlags.settingTimezone = false;
        }
    };

    assignUserToEvent = async (request: eventsApi.AssignUserToEventRequest) => {
        this.progressFlags.assigningUserToEvent = true;

        try {
            const response = await services.Events.assignUserToEvent(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventAssignHelper(this.eventDetails, response.data);

            this.commonStore.incrementCommentLoadingCountToForceReload();
        } finally {
            this.setAssignMenuOpen(false);
            this.progressFlags.assigningUserToEvent = false;
        }
    };

    completeEventReview = async (
        request: eventsApi.CompleteAssessmentReviewRequest,
        assessments: eventModel.AssessmentModel[]
    ) => {
        this.progressFlags.settingCompleteReview = true;

        try {
            const response = await services.Events.completeEventReview(request);

            if (!_.inRange(response.status, 200, 300)) return;

            assessments.forEach((assessment) => {
                assessment.status = eventModel.AssessmentStatus.PendingDecision;
                assessment.isWithFirstEconomicCustomer = request.isWithCustomer;
                assessment.reviewerUserId = this.identityStore.currentUser.id;
                assessment.reviewerFirstName = this.identityStore.currentUser.firstName;
                assessment.reviewerLastName = this.identityStore.currentUser.lastName;
                assessment.reviewedDate = dayjs().toDate();
            });

            if (assessments.every((assessment) => assessment.status === eventModel.AssessmentStatus.PendingDecision)) {
                this.eventDetails.status = eventModel.EventStatus.PendingApproval;
            }
        } finally {
            this.progressFlags.settingCompleteReview = false;
            this.setAssignMenuOpen(true);
        }
    };

    getBatchHistory = async (request: eventsApi.GetBatchHistoryRequest) => {
        this.progressFlags.loadingBatchHistory = true;

        try {
            const response = await services.Events.getBatchHistory(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.eventDetails.assessments
                .filter((x) => x.batchId === request.batchId)
                .forEach((x) => {
                    x.batchHistory = response.data.items;
                });

            this.totalBatchHistoryPages = response.data.totalPages;
            this.totalBatchHistoryCount = response.data.totalCount;
            this.batchHistoryRelevantEventsCount = response.data.relevantEventsCount;

            if (this.currentBatchHistoryPage > response.data.totalPages && response.data.totalPages !== 0) {
                this.currentBatchHistoryPage = response.data.totalPages;
            }
        } finally {
            this.progressFlags.loadingBatchHistory = false;
        }
    };

    setEventType = async (request: eventsApi.SetEventTypeRequest) => {
        this.progressFlags.settingEventType = true;

        try {
            const response = await services.Events.setEventType(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.type = request.eventType;
        } finally {
            this.progressFlags.settingEventType = false;
        }
    };

    loadDeviceData = async (request: eventsApi.GetEventDeviceDataRequest) => {
        this.progressFlags.loadingDeviceData = true;

        try {
            const response = await services.Events.getEventDeviceData(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.devicesData = response.data;
            for (let index = 0; index < this.devicesData.length; index++) {
                this.devicesData[index].borderColor = getColorByIndex(index);
                this.devicesData[index].backgroundColor = getColorByIndex(index);
                this.devicesData[index].borderWidth = 2;
            }
        } finally {
            this.progressFlags.loadingDeviceData = false;
        }
    };

    setDueDate = async (request: eventsApi.SetDueDateRequest) => {
        this.progressFlags.settingDueDate = true;

        try {
            const response = await services.Events.setDueDate(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.dueDate = dateInUTC(request.date, true).toISOString();
        } finally {
            this.progressFlags.settingDueDate = false;
        }
    };

    setQuarantineDate = async (request: eventsApi.SetQuarantineDateRequest) => {
        this.progressFlags.settingQuarantineDate = true;

        try {
            const response = await services.Events.setQuarantineDate(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.quarantineDate = dateInUTC(request.date, true).toISOString();
        } finally {
            this.progressFlags.settingQuarantineDate = false;
        }
    };

    setShippingDate = async (request: eventsApi.SetShippingDateRequest) => {
        this.progressFlags.settingShippingDate = true;

        try {
            const response = await services.Events.setShippingDate(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.shippingDate = dateInUTC(request.date, true).toISOString();
        } finally {
            this.progressFlags.settingShippingDate = false;
        }
    };

    setDeliveryDate = async (request: eventsApi.SetDeliveryDateRequest) => {
        this.progressFlags.settingDeliveryDate = true;

        try {
            const response = await services.Events.setDeliveryDate(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.eventDetails.deliveryDate = dateInUTC(request.date, true).toISOString();
        } finally {
            this.progressFlags.settingDeliveryDate = false;
        }
    };

    resetAllEvents = async (request: eventsApi.ResetAllEventsRequest) => {
        await services.Events.resetAllEvents(request);
        window.location.reload();
    };

    resetEvent = async (request: eventsApi.ResetEventRequest) => {
        await services.Events.resetEvent(request);
        window.location.reload();
    };

    conditionEvent = async (request: eventsApi.ConditionEventRequest) => {
        let response;
        this.progressFlags.isConditioningInProgress = true;

        try {
            response = await services.Events.conditionEvent(request);

            if (!_.inRange(response.status, 200, 300)) return;

            window.location.reload();
        } finally {
            request.callback(response ? response.status : 0);
            this.progressFlags.isConditioningInProgress = false;
        }
    };

    loadEventAttachments = async (request: eventsApi.GetEventAttachmentsRequest, isFirstLoad: boolean) => {
        if (this.progressFlags.loadingAttachments || this.progressFlags.loadingNextSetOfAttachments) {
            return;
        }

        isFirstLoad
            ? (this.progressFlags.loadingAttachments = true)
            : (this.progressFlags.loadingNextSetOfAttachments = true);

        try {
            const response = await services.Events.getEventAttachments(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.attachments = isFirstLoad ? response.data.items : _.concat(this.attachments, response.data.items);
            this.attachmentsTotalCount = response.data.totalCount;
        } finally {
            if (isFirstLoad) {
                this.progressFlags.loadingAttachments = false;
                this.attachmentPageNumber = 1;
            } else {
                this.progressFlags.loadingNextSetOfAttachments = false;
            }
        }
    };

    createManualEvent = async (
        customerId: string,
        authModel: AuthModel,
        justification: string,
        eventModel: eventModel.ManualEventModel,
        callback: (input: number, errors: ApiValidationError[]) => void
    ) => {
        if (this.progressFlags.createEvent) {
            return;
        }

        this.progressFlags.createEvent = true;
        let response: AxiosResponse<any> | null = null;

        const requestModel = this.generateCreateManualEventModel(eventModel);

        try {
            const request: eventsApi.CreateManualEventRequest = {
                authModel,
                customerId,
                justification,
                model: requestModel,
            };

            response = await services.Events.createManualEvent(request);

            if (!_.inRange(response.status, 200, 300)) return;

            // Get id for redirect
            this.eventDetails.id = response.data;
        } finally {
            callback(
                response ? response.status : 0,
                (response?.data as unknown as BaseResponse)?.validationErrors ?? []
            );
            this.progressFlags.createEvent = false;
        }
    };

    getManualEventInformation = async (customerId: string, eventId: string) => {
        this.progressFlags.loadManualEventInformation = true;

        try {
            const response = await services.Events.getManualEventInfo({ customerId: customerId, eventId: eventId });

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.manualEventModelReadOnly = this.setManualEventData(response.data);
            this.eventDetails.id = response.data.id;
            this.eventDetails.displayId = response.data.displayId;
        } finally {
            this.progressFlags.loadManualEventInformation = false;
        }
    };

    editManualEvent = async (
        customerId: string,
        authModel: AuthModel,
        justification: string,
        eventModel: eventModel.ManualEventModel,
        eventId: string,
        callback: (input: number, errors: ApiValidationError[]) => void
    ) => {
        if (this.progressFlags.createEvent) {
            return;
        }

        this.progressFlags.createEvent = true;
        let response: AxiosResponse<any> | null = null;

        try {
            const model = this.generateEditedManualEventModel(eventModel);

            const request: eventsApi.EditManualEventRequest = {
                authModel,
                eventId,
                customerId,
                justification,
                model: model,
            };

            response = await services.Events.editManualEvent(request);

            if (!_.inRange(response.status, 200, 300)) return;

            // Get id for redirect
            this.eventDetails.id = response.data;
        } finally {
            callback(
                response ? response.status : 0,
                (response?.data as unknown as BaseResponse).validationErrors ?? []
            );
            this.progressFlags.createEvent = false;
        }
    };

    getDuplicatesDeviceEvents = async (
        customerId: string,
        eventId: string | undefined,
        devices: string[]
    ): Promise<DuplicatedDeviceEventsResponse[] | undefined> => {
        const requestModel = {
            customerId: customerId,
            eventId: eventId,
            deviceIds: devices,
        };

        this.progressFlags.checkingForDuplicatedDevices = true;
        try {
            const response = await services.Events.getlistEventsWithDuplicateDevices(requestModel);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            return response.data;
        } finally {
            this.progressFlags.checkingForDuplicatedDevices = false;
        }
    };

    // Private methods
    eventAssignHelper = (
        event: eventModel.EventDetails | eventModel.EventListItem,
        response: eventsApi.AssignedUserModel
    ) => {
        if (response.assigneeType === usersModels.AssigneeType.Reviewer) {
            event.reviewerUserId = response.userId;
            event.reviewerFirstName = response.firstName;
            event.reviewerLastName = response.lastName;
            event.status = eventModel.EventStatus.PendingReview;
        } else {
            event.qaUserId = response.userId;
            event.qaFirstName = response.firstName;
            event.qaLastName = response.lastName;
            event.status = eventModel.EventStatus.PendingApproval;
        }
    };

    setManualEventData = (apiModel: eventModel.ManualEventModel): eventModel.ManualEventModel => {
        return {
            id: apiModel.id,
            displayId: apiModel.displayId,
            type: apiModel.type,
            quarantineDate: apiModel.quarantineDate,
            excursionSource: apiModel.excursionSource,
            processType: apiModel.processType,
            timezone: apiModel.timezone,
            deliveryInformation: {
                id: apiModel.deliveryInformation?.id ?? "",
                deliveryNumber: apiModel.deliveryInformation?.deliveryNumber ?? "",
                orderNumbers: apiModel.deliveryInformation?.orderNumbers ?? "",
                laneNumber: apiModel.deliveryInformation?.laneNumber ?? "",
                shipperType: apiModel.deliveryInformation?.shipperType ?? "",
                transportationModeId: apiModel.deliveryInformation?.transportationModeId ?? commonConstants.emptyGuid,
                transportationServiceProviderName:
                    apiModel.deliveryInformation?.transportationServiceProviderName ?? "",
                logisticsServiceProviderName: apiModel.deliveryInformation?.logisticsServiceProviderName ?? "",
                transportationLowerLimit: apiModel.deliveryInformation?.transportationLowerLimit ?? "",
                transportationLowerLimitOperator:
                    apiModel.deliveryInformation?.transportationLowerLimitOperator ??
                    RangeOperatorFrom.GREATER_THAN_OR_EQUAL,
                transportationUpperLimit: apiModel.deliveryInformation?.transportationUpperLimit ?? "",
                transportationUpperLimitOperator:
                    apiModel.deliveryInformation?.transportationUpperLimitOperator ??
                    RangeOperatorTo.LESS_THAN_OR_EQUAL,
                origin: {
                    id: apiModel.deliveryInformation?.origin.id ?? "",
                    code: apiModel.deliveryInformation?.origin.code ?? "",
                    name: apiModel.deliveryInformation?.origin.name ?? "",
                },
                destination: {
                    id: apiModel.deliveryInformation?.destination.id ?? "",
                    code: apiModel.deliveryInformation?.destination.code ?? "",
                    name: apiModel.deliveryInformation?.destination.name ?? "",
                },
            },
            site: {
                id: apiModel.site?.id ?? "",
                code: apiModel.site?.code ?? "",
                name: apiModel.site?.name ?? "",
            },
            batches: apiModel.batches.map((x) => ({
                id: x.id,
                batchNumber: x.batchNumber,
                productId: x.productId ?? "",
                productName: x.productName ?? "",
                doseFormId: x.doseFormId ?? "",
                doseFormName: x.doseFormName ?? "",
                dosage: x.dosage ?? "",
                unitOfMeasureId: x.unitOfMeasureId ?? "",
                unitOfMeasureName: x.unitOfMeasureName ?? "",
                expirationDate: x.expirationDate ?? "",
                eventBatchId: x.eventBatchId,
                quantity: x.quantity === 0 || x.quantity === null ? "" : x.quantity.toString(),
                product: x.product,
                isEditable: x.isEditable,
                rsbAdjustments: x.rsbAdjustments.map((x) => ({
                    ...x,
                    rsbAdjustment: Math.abs(x.rsbAdjustment),
                    rsbAdjustmentAction:
                        x.rsbAdjustment > 0
                            ? eventModel.RsbAdjustmentAction.Add
                            : eventModel.RsbAdjustmentAction.Reduce,
                })),
                reactKey: crypto.randomUUID(),
                showExclamationIcon: !x.productId,
                impactsWithStabilityRangeCount: x.impactsWithStabilityRangeCount,
            })),
            devices: apiModel.devices,
            manualExcursion: {
                lowTemperature: apiModel.manualExcursion?.lowTemperature ?? "",
                highTemperature: apiModel.manualExcursion?.highTemperature ?? "",
                startDateTime: apiModel.manualExcursion?.startDateTime ?? "",
                endDateTime: apiModel.manualExcursion?.endDateTime ?? "",
            },
            manualExcursionDeviceIds: apiModel.manualExcursionDeviceIds ?? "",
            eventAttachments: apiModel.eventAttachments,
            comment: apiModel.comment ?? "",
        };
    };

    resetManualEventData = () => {
        this.manualEventModelReadOnly = this.manualEventModelBlankValue;
    };

    generateCreateManualEventModel = (form: eventModel.ManualEventModel): eventsApi.CreateEventModel => {
        const withManualExcursion = form.excursionSource === eventModel.ExcursionSource.Manual;

        const quarantineDate = form.quarantineDate
            ? dateToFormat(form.quarantineDate, commonConstants.shortDateFormat)
            : null;

        return {
            type: form.type,
            excursionSource: form.excursionSource,
            batches: form.batches.map((x, i) => ({
                id: x.id ? x.id : null,
                batchNumber: x.batchNumber!,
                productId: x.productId!,
                dosage: x.dosage!,
                doseFormId: x.doseFormId!,
                unitOfMeasureId: x.unitOfMeasureId!,
                productName: x.productName!,
                quantity: x.quantity!,
                expirationDate: x.expirationDate
                    ? dateToFormat(x.expirationDate, commonConstants.shortDateFormat)
                    : null,
                position: i,
                rsbAdjustments: [],
            })),
            quarantineDate: quarantineDate,
            deliveryInformation:
                form.type === eventModel.EventType.Site
                    ? null
                    : {
                          deliveryNumber: form.deliveryInformation.deliveryNumber,
                          orderNumbers: form.deliveryInformation.orderNumbers,
                          laneNumber: form.deliveryInformation.laneNumber,
                          shipperType: form.deliveryInformation.shipperType,
                          transportationModeId: form.deliveryInformation.transportationModeId,
                          transportationServiceProvider: form.deliveryInformation.transportationServiceProviderName,
                          logisticsServiceProvider: form.deliveryInformation.logisticsServiceProviderName,
                          transportationRequirements: {
                              lowerLimit: form.deliveryInformation.transportationLowerLimit,
                              lowerLimitOperator: form.deliveryInformation.transportationLowerLimitOperator,
                              upperLimit: form.deliveryInformation.transportationUpperLimit,
                              upperLimitOperator: form.deliveryInformation.transportationUpperLimitOperator,
                          },
                          originId: form.deliveryInformation.origin.id,
                          destinationId: form.deliveryInformation.destination.id,
                      },
            siteId: form.type === eventModel.EventType.Site ? form.site.id : null,
            manualExcursion: withManualExcursion ? form.manualExcursion : null,
            manualExcursionDeviceIds: form.manualExcursionDeviceIds || null,
            devices: form.devices.map((x) => x.id),
            comment: form.comment || null,
            eventAttachments: form.eventAttachments,
        };
    };

    generateEditedManualEventModel = (form: eventModel.ManualEventModel): eventsApi.EditManualEventModel => {
        return {
            ...this.getHeaderData(form),
            ...this.getDeliveryInformation(form.deliveryInformation),
            ...this.getBatches(form.batches),
            ...this.getDevices(form.devices),
            ...this.getManualExcursion(form),
            ...this.getAttachments(form.eventAttachments),
            comment: form.comment,
            siteId: newOrNullIfSame(form.site.id, this.manualEventModelReadOnly.site.id),
        };
    };

    getHeaderData = (form: eventModel.ManualEventModel) => {
        const excursionSource = newOrNullIfSame(form.excursionSource, this.manualEventModelReadOnly.excursionSource);
        let quarantineDate = newOrNullIfSame(form.quarantineDate, this.manualEventModelReadOnly.quarantineDate);

        quarantineDate = quarantineDate ? dateToFormat(quarantineDate, commonConstants.shortDateFormat) : null;

        return { excursionSource, quarantineDate };
    };

    getAttachments = (attachments: eventsApi.AttachmentDataWithFullName[]) => {
        const old = this.manualEventModelReadOnly.eventAttachments;

        const addedAttachments = attachments.filter((x) => old.every((y) => y.id !== x.id));
        const removedAttachments = old.filter((x) => attachments.every((y) => y.id !== x.id));

        return { addedAttachments, removedAttachments };
    };

    getDeliveryInformation = (form: eventModel.DeliveryInformation) => {
        let deliveryInformation: eventsApi.DeliveryInputModel | null = null;

        if (this.manualEventModelReadOnly.type !== eventModel.EventType.Site) {
            const old = this.manualEventModelReadOnly.deliveryInformation;

            deliveryInformation = {
                deliveryNumber: newOrNullIfSame(form.deliveryNumber, old.deliveryNumber),
                orderNumbers: newOrNullIfSame(form.orderNumbers, old.orderNumbers),
                laneNumber: newOrNullIfSame(form.laneNumber, old.laneNumber),
                shipperType: newOrNullIfSame(form.shipperType, old.shipperType),
                transportationModeId: newOrNullIfSame(form.transportationModeId, old.transportationModeId),
                transportationServiceProvider: newOrNullIfSame(
                    form.transportationServiceProviderName,
                    old.transportationServiceProviderName
                ),
                logisticsServiceProvider: newOrNullIfSame(
                    form.logisticsServiceProviderName,
                    old.logisticsServiceProviderName
                ),
                transportationRequirements: this.getTransportationRequirements(form),
                originId: newOrNullIfSame(form.origin.id, old.origin.id),
                destinationId: newOrNullIfSame(form.destination.id, old.destination.id),
            };

            if (areAllFieldsNullDeepCheck(deliveryInformation)) {
                deliveryInformation = null;
            }
        }

        return { deliveryInformation };
    };

    getTransportationRequirements = (form: eventModel.DeliveryInformation) => {
        let model: eventsApi.RangeInputModel | null = null;
        const old = this.manualEventModelReadOnly.deliveryInformation;

        if (
            form.transportationLowerLimit !== old.transportationLowerLimit ||
            form.transportationLowerLimitOperator !== old.transportationLowerLimitOperator ||
            form.transportationUpperLimit !== old.transportationUpperLimit ||
            form.transportationUpperLimitOperator !== old.transportationUpperLimitOperator
        ) {
            model = {
                lowerLimit: form.transportationLowerLimit,
                lowerLimitOperator: form.transportationLowerLimitOperator,
                upperLimit: form.transportationUpperLimit,
                upperLimitOperator: form.transportationUpperLimitOperator,
            };
        }

        return model;
    };

    getBatches = (batches: EventBatchDetailsModel[]) => {
        const old = this.manualEventModelReadOnly.batches;

        const addedBatches: eventsApi.CreateBatchModel[] = batches.reduce(
            (result: eventsApi.CreateBatchModel[], x, i) => {
                if (!x.id || old.every((y) => y.id !== x.id)) {
                    result.push({
                        id: x.id ? x.id : null,
                        batchNumber: x.batchNumber!,
                        productId: x.productId!,
                        dosage: x.dosage!,
                        doseFormId: x.doseFormId!,
                        unitOfMeasureId: x.unitOfMeasureId!,
                        productName: x.productName!,
                        quantity: x.quantity!,
                        expirationDate: x.expirationDate
                            ? dateToFormat(x.expirationDate, commonConstants.shortDateFormat)
                            : null,
                        position: i,
                    });
                }

                return result;
            },
            []
        );

        const editedBatches: eventsApi.EditBatchModel[] = batches.reduce((result: eventsApi.EditBatchModel[], x, i) => {
            if (old.some((y) => y.id === x.id)) {
                const oldValue = old.find((y) => y.id === x.id)!;

                const rsbAdjustmentsEdited = this.getRsbAdjustments(oldValue, x.rsbAdjustments);
                const value = {
                    productId: newOrNullIfSame(x.productId, oldValue.productId),
                    productName: newOrNullIfSame(x.productName, oldValue.productName),
                    doseFormId: newOrNullIfSame(x.doseFormId, oldValue.doseFormId),
                    dosage: newOrNullIfSame(x.dosage, oldValue.dosage),
                    unitOfMeasureId: newOrNullIfSame(x.unitOfMeasureId, oldValue.unitOfMeasureId),
                    expirationDate: newOrNullIfSame(x.expirationDate, oldValue.expirationDate)
                        ? dateToFormat(x.expirationDate, commonConstants.shortDateFormat)
                        : null,
                    quantity: newOrNullIfSame(x.quantity?.toString() ?? "", oldValue.quantity?.toString() ?? ""),
                };

                if (Object.values(value).some((x) => !!x) || rsbAdjustmentsEdited.length > 0) {
                    result.push({
                        ...value,
                        id: x.id,
                        batchNumber: x.batchNumber,
                        position: i,
                        rsbAdjustments: rsbAdjustmentsEdited,
                    });
                }
            }

            return result;
        }, []);

        const removedBatches = old.filter((x) => batches.every((y) => y.id !== x.id)).map((x) => x.id);

        return { addedBatches, editedBatches, removedBatches };
    };

    getRsbAdjustments = (
        oldBatchData: EventBatchDetailsModel,
        rsbAdjustments: eventsApi.RsbAdjustmentForRangeDetails[]
    ) => {
        const result: RsbAdjustmentForRange[] = [];

        rsbAdjustments.forEach((value, i) => {
            const oldValue = oldBatchData.rsbAdjustments.find((x) => x.rangeId === value.rangeId);

            if (
                !!oldValue &&
                (newOrNullIfSame(value.rsbAdjustment.toString(), oldValue.rsbAdjustment.toString()) !== null ||
                    newOrNullIfSame(value.rsbAdjustmentAction, oldValue.rsbAdjustmentAction) !== null)
            ) {
                result.push({
                    position: i,
                    rangeId: value.rangeId,
                    rsbAdjustment:
                        value.rsbAdjustmentAction === eventModel.RsbAdjustmentAction.Add
                            ? value.rsbAdjustment
                            : -value.rsbAdjustment,
                });
                return;
            }

            if (!oldValue) {
                result.push({
                    position: i,
                    rangeId: value.rangeId,
                    rsbAdjustment:
                        value.rsbAdjustmentAction === eventModel.RsbAdjustmentAction.Add
                            ? value.rsbAdjustment
                            : -value.rsbAdjustment,
                });
            }
        });

        result.push(
            ...oldBatchData.rsbAdjustments
                .filter((x) => !rsbAdjustments.some((y) => y.rangeId === x.rangeId))
                .map((x) => ({ rangeId: x.rangeId, rsbAdjustment: null, position: -1 }))
        );

        return result;
    };

    getDevices = (devices: eventModel.DeviceModel[]) => {
        const old = this.manualEventModelReadOnly.devices;

        const addedDevices = devices.filter((x) => old.every((y) => y.id !== x.id)).map((x) => x.id);
        const removedDevices = old.filter((x) => devices.every((y) => y.id !== x.id)).map((x) => x.id);

        return { addedDevices, removedDevices };
    };

    getManualExcursion = (form: eventModel.ManualEventModel) => {
        if (form.excursionSource === eventModel.ExcursionSource.TemperatureRecordingDevice) {
            return { manualExcursionDeviceIds: null, manualExcursion: null };
        }

        const manualExcursionDeviceIds = newOrNullIfSame(
            form.manualExcursionDeviceIds,
            this.manualEventModelReadOnly.manualExcursionDeviceIds
        );

        // @ts-ignore - need to improve models and yup validation to remove this ignore
        const manualExcursion: eventsApi.ManualExcursionInputModel | null = !_.isEqual(
            form.manualExcursion,
            this.manualEventModelReadOnly.manualExcursion
        )
            ? form.manualExcursion
            : null;

        return { manualExcursionDeviceIds, manualExcursion };
    };
}
