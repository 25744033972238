import { BatchDetailsModel } from "api/models/events/eventsApi";
import { EventType, ExcursionSource } from "models/thorEvents/eventModels";
import { helperObjects } from "validation/helper-objects";
import { array, date, lazy, number, object, string } from "yup";
import { manualExcursionValidationSchema } from "./manualExcursionValidationSchema";
import { transportationConditionsValidationSchema } from "./transportationConditionsValidationSchema";

export const createEventValidationSchema = (timezone: string) =>
    lazy((values) => {
        return object({
            type: number()
                .oneOf(Object.values(EventType) as number[], "Invalid value")
                .required(),

            excursionSource: number()
                .oneOf([ExcursionSource.TemperatureRecordingDevice, ExcursionSource.Manual], "Invalid value")
                .required(),
            quarantineDate: date().nullable().notRequired().typeError("Must be a valid date and time"),
            eventAttachments: array()
                .of(
                    object({
                        id: string(),
                        entityId: string(),
                        uploadedBy: string(),
                        createdAt: date(),
                        bucketFileName: string(),
                        fileName: string(),
                        contentType: string(),
                        firstName: string(),
                        lastName: string(),
                    })
                )
                .notRequired(),
            comment: string().notRequired().trim().max(1000),
            deliveryInformation: object()
                .required()
                .when("type", {
                    is: EventType.Transportation,

                    then: object({
                        deliveryNumber: string().required().trim().max(100).test(helperObjects.zerosNotAllowed),
                        orderNumbers: string().notRequired().trim().max(200),
                        laneNumber: string().notRequired().trim().max(100),
                        shipperType: string().notRequired().trim().max(200),
                        transportationModeId: string().notRequired(),
                        transportationServiceProviderName: string().notRequired().trim().max(100),
                        logisticsServiceProviderName: string().notRequired().trim().max(100),
                        origin: object({
                            id: string().required(),
                        }),
                        destination: object({
                            id: string().required(),
                        }),
                    }).concat(transportationConditionsValidationSchema()),
                }),
            site: object().when("type", { is: EventType.Site, then: object({ id: string().required() }) }),
            batches: array()
                .min(1, "You should add at least one batch")
                .required()
                .of(
                    object({
                        batchNumber: string()
                            .trim()
                            .required()
                            .max(100)
                            .test((value, ctx) => {
                                const noDuplicateBatches =
                                    (values.batches as BatchDetailsModel[]).filter((x) => x.batchNumber === value)
                                        .length === 1;

                                return noDuplicateBatches ? true : ctx.createError({ message: "Duplicate batches" });
                            }),
                        productName: string().trim().required().max(200),
                        doseFormId: string().required(),
                        dosage: number().required().moreThan(0).lessThan(10_000_000_000).typeError("Must be a number"),
                        unitOfMeasureId: string().required(),
                        quantity: number().required().integer().min(1).max(1000000).typeError("Must be a number"),
                        expirationDate: string().notRequired().typeError("Must be a valid date and time"),
                        product: object()
                            .nullable()
                            .test((value, ctx) =>
                                value
                                    ? true
                                    : ctx.createError({
                                          message: "Required",
                                          path: `${ctx.path}.stabilityFormFullName`,
                                      })
                            ),
                        rsbAdjustments: array().of(
                            object({
                                rangeId: string().required(),
                                rsbAdjustment: number()
                                    .required()
                                    .moreThan(0)
                                    .test(helperObjects.lessThanOrEqualToForDurationInput)
                                    .typeError("Invalid value"),
                            })
                        ),
                    })
                ),

            devices: array().when("excursionSource", {
                is: ExcursionSource.TemperatureRecordingDevice,
                then: array()
                    .of(
                        object({
                            id: string().required(),
                            serialNumber: string().required(),
                        })
                    )
                    .min(1, "You should add at least one device.")
                    .required(),
            }),

            manualExcursion: object()
                .required()
                .when("excursionSource", {
                    is: (x: ExcursionSource) => x === ExcursionSource.Manual,
                    then: manualExcursionValidationSchema(timezone),
                }),
        });
    });
