import { ReactComponent as AngleSmallDownIcon } from "./arrows/AngleSmallDownIcon.svg";
import { ReactComponent as AngleSmallLeftIcon } from "./arrows/AngleSmallLeftIcon.svg";
import { ReactComponent as AngleSmallRightIcon } from "./arrows/AngleSmallRightIcon.svg";
import { ReactComponent as AngleSmallUpIcon } from "./arrows/AngleSmallUpIcon.svg";
import { ReactComponent as ArrowDownIcon } from "./arrows/ArrowDownIcon.svg";
import { ReactComponent as ArrowLeftIcon } from "./arrows/ArrowLeftIcon.svg";
import { ReactComponent as ArrowRightIcon } from "./arrows/ArrowRightIcon.svg";
import { ReactComponent as ArrowUpIcon } from "./arrows/ArrowUpIcon.svg";
import { ReactComponent as RedoIcon } from "./arrows/RedoIcon.svg";
import { ReactComponent as RevertIcon } from "./arrows/RevertIcon.svg";
import { ReactComponent as SortAscendingIcon } from "./arrows/SortAscendingIcon.svg";
import { ReactComponent as SortDescendingIcon } from "./arrows/SortDescendingIcon.svg";
import { ReactComponent as SortIcon } from "./arrows/SortIcon.svg";
import { ReactComponent as AppsIcon } from "./general/AppsIcon.svg";
import { ReactComponent as ArrowUpRightIcon } from "./general/ArrowUpRightIcon.svg";
import { ReactComponent as AttachmentsIcon } from "./general/AttachmentsIcon.svg";
import { ReactComponent as BulletEmptyIcon } from "./general/BulletEmptyIcon.svg";
import { ReactComponent as BulletIcon } from "./general/BulletIcon.svg";
import { ReactComponent as CalendarIcon } from "./general/CalendarIcon.svg";
import { ReactComponent as CheckIcon } from "./general/CheckIcon.svg";
import { ReactComponent as CheckSmallIcon } from "./general/CheckSmallIcon.svg";
import { ReactComponent as CommentsIcon } from "./general/CommentsIcon.svg";
import { ReactComponent as CopyIcon } from "./general/CopyIcon.svg";
import { ReactComponent as CrossIcon } from "./general/CrossIcon.svg";
import { ReactComponent as CrossSmallIcon } from "./general/CrossSmallIcon.svg";
import { ReactComponent as DownloadIcon } from "./general/DownloadIcon.svg";
import { ReactComponent as DragIcon } from "./general/DragIcon.svg";
import { ReactComponent as DurationIcon } from "./general/DurationIcon.svg";
import { ReactComponent as EditIcon } from "./general/EditIcon.svg";
import { ReactComponent as ErrorIcon } from "./general/ErrorIcon.svg";
import { ReactComponent as EventCategoriesIcon } from "./general/EventCategoriesIcon.svg";
import { ReactComponent as EventCodeIcon } from "./general/EventCodeIcon.svg";
import { ReactComponent as ExportIcon } from "./general/ExportIcon.svg";
import { ReactComponent as EyeCrossedIcon } from "./general/EyeCrossedIcon.svg";
import { ReactComponent as EyeIcon } from "./general/EyeIcon.svg";
import { ReactComponent as FlagIcon } from "./general/FlagIcon.svg";
import { ReactComponent as HistoryIcon } from "./general/HistoryIcon.svg";
import { ReactComponent as InfoIcon } from "./general/InfoIcon.svg";
import { ReactComponent as InfoIconS } from "./general/InfoIconS.svg";
import { ReactComponent as LocationIcon } from "./general/LocationIcon.svg";
import { ReactComponent as LogoutIcon } from "./general/LogoutIcon.svg";
import { ReactComponent as MenuDotsVerticalIcon } from "./general/MenuDotsVerticalIcon.svg";
import { ReactComponent as MinusIcon } from "./general/MinusIcon.svg";
import { ReactComponent as MinusSmallIcon } from "./general/MinusSmallIcon.svg";
import { ReactComponent as NewVersionIcon } from "./general/NewVersionIcon.svg";
import { ReactComponent as PlusIcon } from "./general/PlusIcon.svg";
import { ReactComponent as PlusSmallIcon } from "./general/PlusSmallIcon.svg";
import { ReactComponent as PrintIcon } from "./general/PrintIcon.svg";
import { ReactComponent as SearchIcon } from "./general/SearchIcon.svg";
import { ReactComponent as TextAreaIcon } from "./general/TextAreaIcon.svg";
import { ReactComponent as TimeIcon } from "./general/TimeIcon.svg";
import { ReactComponent as TransportationIcon } from "./general/TransportationIcon.svg";
import { ReactComponent as TrashIcon } from "./general/TrashIcon.svg";
import { ReactComponent as TriangleWarningIcon } from "./general/TriangleWarningIcon.svg";
import { ReactComponent as VersionIcon } from "./general/VersionIcon.svg";
import { ReactComponent as CarrierLogoIcon } from "./menu/CarrierLogoIcon.svg";
import { ReactComponent as ExcursionEvaluationIcon } from "./menu/ExcursionEvaluationIcon.svg";
import { ReactComponent as LynxLogo2LettersIcon } from "./menu/LynxLogo2LettersIcon.svg";
import { ReactComponent as LynxLogo4LettersIcon } from "./menu/LynxLogo4LettersIcon.svg";
import { ReactComponent as PlanningForecastingIcon } from "./menu/PlanningForecastingIcon.svg";
import { ReactComponent as RiskAssessmentIcon } from "./menu/RiskAssessmentIcon.svg";
import { ReactComponent as StabilityFormFillIcon } from "./menu/StabilityFormFillIcon.svg";
import { ReactComponent as StabilityFormIcon } from "./menu/StabilityFormIcon.svg";
import { ReactComponent as StructureIcon } from "./menu/StructureIcon.svg";
import { ReactComponent as UsersIcon } from "./menu/UsersIcon.svg";

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";

const iconsNonTyped = {
    angleSmallDown: { icon: AngleSmallDownIcon },
    angleSmallLeft: { icon: AngleSmallLeftIcon },
    angleSmallRight: { icon: AngleSmallRightIcon },
    angleSmallUp: { icon: AngleSmallUpIcon },
    arrowDown: { icon: ArrowDownIcon },
    arrowLeft: { icon: ArrowLeftIcon },
    arrowRight: { icon: ArrowRightIcon },
    arrowUp: { icon: ArrowUpIcon },
    redo: { icon: RedoIcon },
    revert: { icon: RevertIcon },
    sortAscending: { icon: SortAscendingIcon },
    sortDescending: { icon: SortDescendingIcon },
    sort: { icon: SortIcon },
    apps: { icon: AppsIcon },
    arrowUpRight: { icon: ArrowUpRightIcon },
    attachments: { icon: AttachmentsIcon },
    bulletEmpty: { icon: BulletEmptyIcon },
    bullet: { icon: BulletIcon },
    calendar: { icon: CalendarIcon },
    check: { icon: CheckIcon },
    checkSmall: { icon: CheckSmallIcon },
    comments: { icon: CommentsIcon },
    copy: { icon: CopyIcon },
    cross: { icon: CrossIcon },
    crossSmall: { icon: CrossSmallIcon },
    download: { icon: DownloadIcon },
    drag: { icon: DragIcon },
    duration: { icon: DurationIcon },
    edit: { icon: EditIcon },
    error: { icon: ErrorIcon },
    eventCategories: { icon: EventCategoriesIcon },
    eventCode: { icon: EventCodeIcon },
    export: { icon: ExportIcon },
    eyeCrossed: { icon: EyeCrossedIcon },
    eye: { icon: EyeIcon },
    flag: { icon: FlagIcon },
    history: { icon: HistoryIcon },
    info: { icon: InfoIcon },
    infoS: { icon: InfoIconS },
    location: { icon: LocationIcon },
    logout: { icon: LogoutIcon },
    menuDotsVertical: { icon: MenuDotsVerticalIcon },
    minus: { icon: MinusIcon },
    minusSmall: { icon: MinusSmallIcon },
    newVersion: { icon: NewVersionIcon },
    plus: { icon: PlusIcon },
    plusSmall: { icon: PlusSmallIcon },
    print: { icon: PrintIcon },
    search: { icon: SearchIcon },
    textArea: { icon: TextAreaIcon },
    time: { icon: TimeIcon },
    transportation: { icon: TransportationIcon },
    trash: { icon: TrashIcon },
    triangleWarning: { icon: TriangleWarningIcon },
    version: { icon: VersionIcon },
    carrierLogo: { icon: CarrierLogoIcon, className: "carrierLogo" },
    excursionEvaluation: { icon: ExcursionEvaluationIcon },
    lynxLogo2Letters: { icon: LynxLogo2LettersIcon, className: "lynxLogo2Letters" },
    lynxLogo4Letters: { icon: LynxLogo4LettersIcon, className: "lynxLogo4Letters" },
    planningForecasting: { icon: PlanningForecastingIcon },
    riskAssessment: { icon: RiskAssessmentIcon },
    stabilityFormFill: { icon: StabilityFormFillIcon },
    stabilityForm: { icon: StabilityFormIcon },
    structure: { icon: StructureIcon },
    users: { icon: UsersIcon },
} as const;

const lynxIconStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "1rem",
            height: "1rem",
            fill: theme.colors.neutrals.neutral500,
        },

        carrierLogo: {
            width: "4.75rem",
            height: "1.95rem",
            fill: theme.colors.neutrals.neutral500,
        },

        lynxLogo2Letters: {
            width: "3rem",
            height: "3rem",
            fill: theme.colors.neutrals.neutral500,
        },

        lynxLogo4Letters: {
            width: "3.875rem",
            height: "1.19rem",
            fill: theme.colors.neutrals.neutral500,
        },
    })
);

type IconClassName = keyof ReturnType<typeof lynxIconStyles>;

type IconData = {
    icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string;
        }
    >;
    className?: IconClassName;
};

type IconsStorage<T> = {
    [P in keyof T]: IconData;
};

const icons: IconsStorage<typeof iconsNonTyped> = iconsNonTyped;

export type IconName = keyof typeof icons;
type LynxIconProps = { name: IconName } & React.SVGProps<SVGSVGElement> & { title?: string };

export const LynxIcon = (props: LynxIconProps) => {
    const { name, className, ...rest } = { ...props };
    const classes = lynxIconStyles();

    const iconObj = icons[props.name];
    const Icon = iconObj.icon;
    const defaultClassName = classes[iconObj.className ?? "root"];

    return <Icon className={clsx(defaultClassName, className)} {...rest} />;
};
