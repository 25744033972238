import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { getColor } from "helpers/getColorByIndex";

interface ModuleChipStylesProps {}

export const moduleChipStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: "5px",
            borderRadius: "5px",
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: 500,
            color: theme.colors.neutrals.white,

            backgroundColor: () => {
                return getColor();
            },
        },
    })
);
