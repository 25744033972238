import { Chip } from "@material-ui/core";
import { moduleChipStyles } from "./ModuleChipStyles";

export interface ModuleChipProps {
    text?: string;
}

export const ModuleChip = ({ text }: ModuleChipProps) => {
    const classes = moduleChipStyles();

    if (!text) {
        return null;
    }

    return <Chip className={classes.root} label={text} />;
};
