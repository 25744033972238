import { apiRoutes } from "api/apiRoutes";
import { BatchDetailsModel } from "api/models/events/eventsApi";
import { CustomerLevelRequest } from "api/models/sharedModels/CustomerLevelRequest";
import { ProductDetailsModel } from "api/models/stabilityForms/stabilityFormsApi";
import * as userManagementApi from "api/models/userManagement/userManagementApi";
import { torAxiosIgnoreErrors } from "api/serviceConfig";
import { AxiosRequestConfig } from "axios";
import {
    ProductSupportInfo,
    ProductSupportInfoBase,
    Region,
} from "models/productAndStabilityForm/productAndStabilityFormModels";
import { Page } from "models/shared/Page";
import * as eventModel from "models/thorEvents/eventModels";

export class CustomersDataService {
    getCustomerRegions = async (request: CustomerLevelRequest) => {
        const url = apiRoutes.customers.getCustomerRegions(request.customerId);
        const response = await torAxiosIgnoreErrors.get<Region[]>(url);
        return response;
    };

    getCustomerEventPriorities = async (request: CustomerLevelRequest) => {
        const url = apiRoutes.customers.getCustomerEventPriorities(request.customerId);
        const result = await torAxiosIgnoreErrors.get<userManagementApi.CustomerEventPriorityResponse[]>(url);
        return result;
    };

    getCustomerEventCodes = async (request: CustomerLevelRequest) => {
        const url = apiRoutes.customers.getCustomerEventCodes(request.customerId);
        const result = await torAxiosIgnoreErrors.get<eventModel.EventCode[]>(url);
        return result;
    };

    getCustomerDeliveries = async (request: userManagementApi.CustomersDataWithSearch) => {
        const url = apiRoutes.customers.getCustomerDeliveries(request.customerId);
        const requestConfig: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                searchValue: request.searchValue,
            },
        };
        const result = await torAxiosIgnoreErrors.get<Page<eventModel.DeliveryInformation>>(url, requestConfig);
        return result;
    };

    getCustomerTransportationModes = async (request: CustomerLevelRequest) => {
        const url = apiRoutes.customers.getCustomerTransportationModes(request.customerId);
        const result = await torAxiosIgnoreErrors.get<eventModel.TransportationMode[]>(url);
        return result;
    };

    getCustomerLocations = async (request: userManagementApi.CustomersDataWithSearch) => {
        const url = apiRoutes.customers.getCustomerLocations(request.customerId);
        const requestConfig: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                searchValue: request.searchValue,
            },
        };
        const result = await torAxiosIgnoreErrors.get<Page<eventModel.LocationDescriptionModel>>(url, requestConfig);
        return result;
    };

    getCustomerBatches = async (request: userManagementApi.CustomersDataWithSearch) => {
        const url = apiRoutes.customers.getCustomerBatches(request.customerId);

        const requestConfig: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                searchValue: request.searchValue,
            },
        };

        const result = await torAxiosIgnoreErrors.get<Page<BatchDetailsModel>>(url, requestConfig);
        return result;
    };

    getCustomerDevices = async (request: userManagementApi.CustomersDataWithSearch) => {
        const url = apiRoutes.customers.getCustomerDevices(request.customerId);
        const requestConfig: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                searchValue: request.searchValue,
            },
        };
        const result = await torAxiosIgnoreErrors.get<Page<eventModel.DeviceModel>>(url, requestConfig);
        return result;
    };

    getCustomerProductSupportInfo = async (request: CustomerLevelRequest) => {
        const url = apiRoutes.customers.getCustomerProductSupportInfo(request.customerId);

        const result = await torAxiosIgnoreErrors.get<ProductSupportInfo>(url);
        return result;
    };

    getCustomerProductUnitOfMeasure = async (request: CustomerLevelRequest) => {
        const url = apiRoutes.customers.getCustomerProductUnitOfMeasure(request.customerId);

        const result = await torAxiosIgnoreErrors.get<ProductSupportInfoBase[]>(url);
        return result;
    };

    getCustomerProducts = async (request: userManagementApi.CustomersDataWithSearch) => {
        const url = apiRoutes.customers.getCustomerProducts(request.customerId);

        const requestConfig: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                searchValue: request.searchValue,
            },
        };

        const result = await torAxiosIgnoreErrors.get<Page<ProductDetailsModel>>(url, requestConfig);
        return result;
    };
}
