import React from "react";
import { lynxTabStyles } from "./LynxTabStyles";
import { Button } from "@material-ui/core";
import LynxTypography from "../LynxTypography/LynxTypography";
import { LynxTabProps } from "./LynxTabProps";
import clsx from "clsx";

export const LynxTab = (props: LynxTabProps) => {
    const classes = lynxTabStyles({ tabPosition: props.tabPosition });

    const { className, ...buttonProps } = props.buttonProps;
    const buttonClassName = clsx(classes.button, className, {
        [classes.selected]: props.isSelected,
    });

    return (
        <Button {...buttonProps} className={buttonClassName}>
            <LynxTypography
                variant="body-medium"
                color={props.buttonProps.disabled ? "neutral300" : props.isSelected ? "blue500" : "neutral600"}
            >
                {props.tabText}
            </LynxTypography>
        </Button>
    );
};
