import { Dayjs } from "dayjs";
import { dateInLocalTimezone, dateInUTC } from "./timezoneHelper";

export type possibleDateTypes = Date | string | number | null | undefined | Dayjs;

export function dateToFormat(date: possibleDateTypes, format: string, isInUtc?: boolean): string {
    const dayjsDate = isInUtc ? dateInUTC(date) : dateInLocalTimezone(date);

    return dayjsDate.format(format);
}
