import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const eventLocationInfoStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginTop: "0.5rem",
        },

        subtitle: {
            marginBottom: "0.5rem",
        },

        paddingBottomZero: {
            paddingBottom: "0 !important",
        },

        contentStyles: {
            overflowWrap: "break-word",
            wordWrap: "break-word",
            msWordBreak: "break-all",
            wordBreak: "break-word",
        },

        icon: {
            fill: theme.colors.neutrals.neutral400,
            marginLeft: "1rem",
            width: "0.875rem",
            height: "0.875rem",
        },

        emptyValue: {
            color: theme.colors.neutrals.neutral300,
            textTransform: "uppercase",
        },

        transportationTooltip: {
            backgroundColor: theme.colors.neutrals.neutral600,
            color: theme.palette.common.white,
            paddingLeft: "1rem",
            minWidth: "20rem",
        },

        transportationTooltipArrow: {
            "&:before": {
                backgroundColor: theme.colors.neutrals.neutral600,
            },
        },
    })
);
