import { Divider, Drawer } from "@material-ui/core";
import clsx from "clsx";
import { getPermissionKey } from "helpers/permissionHelpers";
import { IconName, LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { SidebarPermissions } from "models/commonModels";
import { actions } from "models/userManagement/actions";
import { Link, NavLink } from "react-router-dom";
import routes from "routes";
import { useStore } from "store/StoreConfigs";
import { lynxSidebarStyles } from "./LynxSidebarStyles";
import { UserGuideMenu } from "./UserGuideMenu";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";

interface LogoProps {
    route: string;
    disabled?: boolean;
}

const Logo = ({ route, disabled }: LogoProps) => {
    const classes = lynxSidebarStyles();

    return (
        <Link
            tabIndex={disabled ? -1 : 0}
            to={route}
            onClick={(e) => disabled && e.preventDefault()}
            className={clsx(classes.logo, { [classes.interactionsDisabled]: disabled })}
        >
            <LynxIcon
                name="lynxLogo2Letters"
                className={classes.lynx2LetterLogoIcon}
                role="img"
                aria-labelledby="Lynx Logo"
            />
        </Link>
    );
};

interface NavItemProps {
    iconName: IconName;
    text: string;
    route: string;
    disabled?: boolean;
}

const NavItem = ({ iconName, text, route, disabled }: NavItemProps) => {
    const classes = lynxSidebarStyles();

    return (
        <PromptTooltip placement="right" title={text}>
            <div>
                <NavLink
                    tabIndex={disabled ? -1 : 0}
                    to={route}
                    onClick={(e) => disabled && e.preventDefault()}
                    className={({ isActive }) =>
                        clsx(classes.navItem, {
                            [classes.navItemActive]: isActive && !disabled,
                            [classes.navItemDisabled]: disabled,
                            [classes.interactionsDisabled]: disabled,
                        })
                    }
                >
                    <LynxIcon name={iconName} className={classes.icon} />
                </NavLink>
            </div>
        </PromptTooltip>
    );
};

const useSidebarPermissions = () => {
    const {
        identityStore,
        permissionsStore: { hasPermission },
    } = useStore();

    const customerId = identityStore.currentCustomer.id;

    const userManagementPermissions = identityStore.isSystemSpace
        ? [
              getPermissionKey(actions.system.customers.view),
              getPermissionKey(actions.system.customers.manage),
              getPermissionKey(actions.system.users.view),
              getPermissionKey(actions.system.users.manage),
              getPermissionKey(actions.system.groups.view),
              getPermissionKey(actions.system.groups.manage),
          ]
        : [
              getPermissionKey(actions.customer.users.view, customerId),
              getPermissionKey(actions.customer.users.manage, customerId),
              getPermissionKey(actions.customer.groups.view, customerId),
              getPermissionKey(actions.customer.groups.manage, customerId),
          ];

    const result: SidebarPermissions = {
        excursionEvaluation: hasPermission(getPermissionKey(actions.customer.tor.events.view, customerId)),
        stabilityDataManagement: hasPermission(getPermissionKey(actions.customer.tor.stabilityForms.view, customerId)),
        batchLookup: false,
        userManagement: hasPermission(...userManagementPermissions),
    };

    return result;
};

export const LynxSidebar = observer(() => {
    const classes = lynxSidebarStyles();
    const permissions = useSidebarPermissions();

    return (
        <Drawer
            variant="permanent"
            className={classes.sidebar}
            classes={{ paper: clsx(classes.drawerPaper, classes.sidebar) }}
        >
            <Logo route={routes.events} disabled={!permissions.excursionEvaluation} />
            <Divider className={clsx(classes.divider, classes.dividerFirst)} />

            <NavItem
                iconName="excursionEvaluation"
                text="Excursion Impact Evaluation"
                route={routes.events}
                disabled={!permissions.excursionEvaluation}
            />
            <NavItem
                iconName="stabilityFormFill"
                text="Stability Data Management"
                route={routes.stabilityForms}
                disabled={!permissions.stabilityDataManagement}
            />
            <NavItem
                iconName="structure"
                text="Batch Lookup"
                route="/batchLookup"
                disabled={!permissions.batchLookup}
            />
            <Divider className={clsx(classes.divider, classes.dividerSecond)} />

            <NavItem
                iconName="users"
                text="User Management"
                route={routes.userManagement}
                disabled={!permissions.userManagement}
            />
            <Divider className={clsx(classes.divider, classes.dividerSecond)} />

            <UserGuideMenu permissions={permissions} />
        </Drawer>
    );
});
