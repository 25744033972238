import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { LynxTabProps } from "./LynxTabProps";

export const lynxTabStyles = makeStyles<Theme, Pick<LynxTabProps, "tabPosition">>((theme) =>
    createStyles({
        button: ({ tabPosition }) => ({
            "&.MuiButton-root": {
                minWidth: "16rem",
                padding: "0.5rem 0rem",
                border: `1px solid ${theme.colors.primary.blue300}`,
                textTransform: "capitalize",

                borderRadius:
                    tabPosition === "leftTab"
                        ? "0.25rem 0rem 0rem 0.25rem"
                        : tabPosition === "middleTab"
                        ? "0.25rem 0.25rem 0.25rem 0.25rem"
                        : "0rem 0.25rem 0.25rem 0rem",
            },

            "&:hover": {
                backgroundColor: theme.colors.primary.blue100,
            },

            "&:focus-visible": {
                boxShadow: theme.focusShadow,
                border: `0.25rem solid ${theme.colors.dataVisualization.orange}`,
            },

            "&:disabled": {
                cursor: "not-allowed",
                color: theme.colors.neutrals.neutral300,
                backgroundColor: "inherit",
                border: `1px solid ${theme.colors.neutrals.neutral300}`,
            },

            "& path": {
                fill: "currentColor",
            },
        }),
        selected: {
            backgroundColor: theme.colors.primary.blue200,
            border: `1px solid ${theme.colors.primary.blue300}`,

            "&.MuiButton-root:hover": {
                backgroundColor: theme.colors.primary.blue200,
                cursor: "default",
            },
        },
    })
);
