import { apiRoutes } from "api/apiRoutes";
import * as auditApi from "api/models/audit/auditApi";
import { torAxios, torAxiosIgnoreErrors } from "api/serviceConfig";
import { BaseResponse } from "api/models/sharedModels/ResponseModels";
import { AxiosRequestConfig } from "axios";

export class AuditService {
    getAuditEntries = async (request: auditApi.GetAuditEntriesRequest) => {
        const { customerId, ...params } = request;

        const url = apiRoutes.audit.getAuditEntries(customerId);
        const requestConfig: AxiosRequestConfig = { params };

        return await torAxiosIgnoreErrors.get<auditApi.GetAuditEntriesResponse>(url, requestConfig);
    };

    getHumanReadableCustomerAuditEntries = async (request: auditApi.GetHumanReadableCustomerAuditEntriesRequest) => {
        const { customerId, ...params } = request;

        const url = apiRoutes.audit.getHumanReadableCustomerAuditEntries(customerId);
        const requestConfig: AxiosRequestConfig = { params };

        return await torAxiosIgnoreErrors.get<auditApi.GetHumanReadableAuditEntriesResponse>(url, requestConfig);
    };

    getHumanReadableSystemAuditEntries = async (request: auditApi.GetHumanReadableSystemAuditEntriesRequest) => {
        const url = apiRoutes.audit.getHumanReadableSystemAuditEntries;
        const requestConfig: AxiosRequestConfig = { params: request };

        return await torAxiosIgnoreErrors.get<auditApi.GetHumanReadableAuditEntriesResponse>(url, requestConfig);
    };

    getCustomerAuditEntityShortInfo = async (request: auditApi.GetCustomerAuditEntityShortInfoRequest) => {
        const { customerId, ...params } = { ...request };

        const url = apiRoutes.audit.getCustomerAuditEntityShortInfo(customerId);
        const requestConfig: AxiosRequestConfig = { params };

        return await torAxiosIgnoreErrors.get<auditApi.AuditEntityShortInfoResponse>(url, requestConfig);
    };

    getSystemAuditEntityShortInfo = async (request: auditApi.GetSystemAuditEntityShortInfoRequest) => {
        const url = apiRoutes.audit.getSystemAuditEntityShortInfo;
        const requestConfig: AxiosRequestConfig = { params: request };

        return await torAxiosIgnoreErrors.get<auditApi.AuditEntityShortInfoResponse>(url, requestConfig);
    };

    exportCustomerAuditEntriesToPdf = async (request: auditApi.ExportCustomerAuditEntriesToPdfRequest) => {
        const { customerId, ...params } = request;

        const url = apiRoutes.audit.exportCustomerAuditEntriesToPdf(customerId);
        const requestConfig: AxiosRequestConfig = { params, responseType: "blob" };

        return await torAxios.get<Blob>(url, requestConfig);
    };

    exportSystemAuditEntriesToPdf = async (request: auditApi.ExportSystemAuditEntriesToPdfRequest) => {
        const url = apiRoutes.audit.exportSystemAuditEntriesToPdf;
        const requestConfig: AxiosRequestConfig = { params: request, responseType: "blob" };

        return await torAxios.get<Blob>(url, requestConfig);
    };

    exportCustomerAuditEntriesToCsv = async (request: auditApi.ExportCustomerAuditEntriesToCsvRequest) => {
        const { customerId, ...params } = request;

        const url = apiRoutes.audit.exportCustomerAuditEntriesToCsv(customerId);
        const requestConfig: AxiosRequestConfig = { params, responseType: "blob" };

        return await torAxios.get(url, requestConfig);
    };

    exportSystemAuditEntriesToCsv = async (request: auditApi.ExportSystemAuditEntriesToCsvRequest) => {
        const url = apiRoutes.audit.exportSystemAuditEntriesToCsv;
        const requestConfig: AxiosRequestConfig = { params: request, responseType: "blob" };

        return await torAxios.get(url, requestConfig);
    };

    auditUserSignInSuccess = async () => {
        const url = apiRoutes.audit.auditUserSignInSuccess;

        return await torAxios.post<BaseResponse>(url);
    };

    auditUserSignInFailure = async (request: auditApi.AuditUserSignInFailureRequest) => {
        const url = apiRoutes.audit.auditUserSignInFailure;
        const body = { ...request };

        return await torAxios.post<BaseResponse>(url, body);
    };
}
