import { Grid } from "@material-ui/core";
import InfoSubheader from "components/ReusableComponents/InfoSubheader";
import { LynxIcon } from "icons/LynxIcon";
import { useStore } from "store/StoreConfigs";
import { InfoRow, InfoRowItem } from "./eventLocationInfoHelpers";
import { eventLocationInfoStyles } from "./eventLocationInfoStyles";

const EventSiteLocationInfo = () => {
    const classes = eventLocationInfoStyles();
    const { thorEventViewStore } = useStore();
    const event = thorEventViewStore.eventDetails;

    return (
        <Grid container spacing={4} className={classes.container}>
            <Grid item xs={12} className={classes.paddingBottomZero}>
                <InfoSubheader text="location information" icon={<LynxIcon name="location" />} />
            </Grid>
            <InfoRow>
                <InfoRowItem label="Site Address" value={event.site.addressLine1} />
                <InfoRowItem label="Site ID" value={event.site.code} />
                <InfoRowItem label="Site Name" value={event.site.name} />
            </InfoRow>
        </Grid>
    );
};

export default EventSiteLocationInfo;
