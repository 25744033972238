import { apiRoutes } from "api/apiRoutes";
import { torAxios, torAxiosIgnoreErrors } from "api/serviceConfig";
import * as userManagementApi from "api/models/userManagement/userManagementApi";

export class PermissionsService {
    evaluatePermissions = async (request: userManagementApi.EvaluatePermissionsRequest) => {
        const url = apiRoutes.permissions.evaluatePermissions;

        const body = {
            permissions: request.permissions,
        };

        const response = await torAxios.post<userManagementApi.PermissionEvaluationResponse[]>(url, body);
        return response;
    };

    listSystemPermissions = async (request?: userManagementApi.ListSystemPermissionsRequest) => {
        const url = apiRoutes.permissions.listSystemPermissions;
        const response = await torAxiosIgnoreErrors.get<userManagementApi.PermissionListItemResponse[]>(url);
        return response;
    };

    listCustomerPermissions = async (request: userManagementApi.ListCustomerPermissionsRequest) => {
        const url = apiRoutes.permissions.listCustomerPermissions(request?.customerId);
        const response = await torAxiosIgnoreErrors.get<userManagementApi.PermissionListItemResponse[]>(url);
        return response;
    };

    listSystemRoles = async (request?: userManagementApi.ListSystemRolesRequest) => {
        const url = apiRoutes.permissions.listSystemRoles;
        const response = await torAxiosIgnoreErrors.get<userManagementApi.RoleListItemResponse[]>(url);
        return response;
    };

    listCustomerRoles = async (request: userManagementApi.ListCustomerRolesRequest) => {
        const url = apiRoutes.permissions.listCustomerRoles(request?.customerId);
        const response = await torAxiosIgnoreErrors.get<userManagementApi.RoleListItemResponse[]>(url);
        return response;
    };
}
