import { ApiValidationError } from "api/models/sharedModels/ApiValidationError";
import { AuthModel } from "api/models/sharedModels/AuthModel";
import { BaseResponse } from "api/models/sharedModels/ResponseModels";
import * as stabilityFormApi from "api/models/stabilityForms/stabilityFormsApi";
import { services } from "api/serviceConfig";
import { SearchParameter } from "components/LynxComponents/LynxSearch";
import {
    allCountriesAndRegions,
    correctStringValueForCreateRequest,
    correctStringValueForEditRequest,
} from "components/StabilityForm/helper-functions";
import dayjs from "dayjs";
import { FormikErrors } from "formik";
import { dateToFormat } from "helpers/dateFormattingHelper";
import { extractFileNameFromContentDisHeader, forceFileDownload } from "helpers/fileHelpers";
import _ from "lodash";
import { commonConstants } from "lynxConstants";
import { makeAutoObservable } from "mobx";
import * as stabilityFormAndProductModels from "models/productAndStabilityForm/productAndStabilityFormModels";
import CommonStore from "./CommonStore";
import { localStorageService } from "helpers/localStorageService";
import { PaginationArea } from "models/shared/Page";

export class StabilityFormStore {
    constructor(commonStore: CommonStore) {
        makeAutoObservable(this);
        this.commonStore = commonStore;
    }

    commonStore: CommonStore;

    // Stability Form Errors
    formErrors: FormikErrors<stabilityFormAndProductModels.StabilityFormModel> = {};
    setErrors = (errors: FormikErrors<stabilityFormAndProductModels.StabilityFormModel>) => (this.formErrors = errors);

    // Stability Forms
    stabilityForms: stabilityFormAndProductModels.StabilityFormListItem[] = [];

    formDetails: stabilityFormAndProductModels.StabilityFormDefinition = {
        id: "",
        number: "",
        version: "",
        effectiveDate: "",
        customerId: "",
        ownerEmail: "",
        ownerFirstName: "",
        ownerLastName: "",
        product: {
            id: "",
            displayId: "",
            name: "",
            genericName: "",
            storageConditionFrom: "",
            rangeOperatorFrom: stabilityFormAndProductModels.RangeOperatorFrom.GREATER_THAN_OR_EQUAL,
            storageConditionTo: "",
            rangeOperatorTo: stabilityFormAndProductModels.RangeOperatorTo.LESS_THAN_OR_EQUAL,
            SKUs: [],
            storageTypeId: "",
            storageType: "",
            presentationId: "",
            presentation: "",
            categoryId: "",
            category: "",
            expiryPeriodInHours: "",
            productSpec: "",
            freezeCycleLimit: "",
            heatCycleLimit: "",
            representations: [
                {
                    id: "",
                    dosage: "",
                    unitOfMeasureId: "",
                    unitOfMeasure: "",
                    doseFormId: "",
                    doseForm: "",
                    allDosagesFlag: "false",
                },
            ],
        },
        temperatureRanges: [
            {
                id: "",
                lowerLimit: "",
                rangeOperatorFrom: stabilityFormAndProductModels.RangeOperatorFrom.GREATER_THAN_OR_EQUAL,
                upperLimit: "",
                rangeOperatorTo: stabilityFormAndProductModels.RangeOperatorTo.LESS_THAN_OR_EQUAL,
                references: "",
                totalStabilityBudget: "",
                remainingStabilityBudget: "",
                medicalInfo: "",
                isReferenced: false,
                rangeRegions: [],
            },
        ],
    };

    // Stability Form Changes History
    stabilityFormChangesHistory: stabilityFormAndProductModels.StabilityFormChangesHistory[] = [];
    currentHistoryPage = 1;
    totalHistoryPages = 1;
    pageHistorySize = localStorageService.getPageSize(PaginationArea.StabilityFormHistory) ?? 10;

    get isLastHistoryPage() {
        return this.currentHistoryPage === this.totalHistoryPages;
    }

    setHistoryPage = (page: number) => {
        this.currentHistoryPage = page;
    };

    setHistoryPageSize = (pageSize: number) => {
        this.pageHistorySize = pageSize;
    };

    clearHistoryPagination = () => {
        this.stabilityFormChangesHistory = [];
        this.currentHistoryPage = 1;
        this.totalHistoryPages = 1;
    };

    moveToHistoryPage = (direction: "back" | "forward") => {
        direction === "back" ? this.currentHistoryPage-- : this.currentHistoryPage++;
    };

    // Pagination
    currentPage = 1;
    totalPages = 1;
    totalCount = 0;
    pageSize = localStorageService.getPageSize(PaginationArea.StabilityForms) ?? 10;

    get isLastPage() {
        return this.currentPage === this.totalPages;
    }

    setPage = (page: number) => {
        this.currentPage = page;
    };

    setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    };

    clearPagination = () => {
        this.stabilityForms = [];
        this.currentPage = 1;
        this.totalPages = 1;
    };

    moveToPage = (direction: "back" | "forward") => {
        direction === "back" ? this.currentPage-- : this.currentPage++;
    };

    // Filtering
    formOwnerSelectedFilters: string[] = [];

    searchValue: string = "";
    searchParameter: SearchParameter<"product"> = "ProductName";

    get searchInputTrimmed() {
        return this.searchValue.trim();
    }

    setSearchParameter = (value: SearchParameter<"product">) => {
        this.searchParameter = value;
    };

    setSearchValue = (value: string) => {
        this.searchValue = value;
    };

    setFormOwnerFilter = (value: string[]) => {
        this.formOwnerSelectedFilters = value.filter((x) => x);
    };

    resetAllFilters = () => {
        this.formOwnerSelectedFilters = [];
    };

    // Loading flags
    progressFlags = {
        loadingStabilityForms: false,
        loadingStabilityForm: false,
        loadingStabilityFormHistoryChanges: false,
        loadingReAuthentication: false,
        exportingPdf: false,
    };

    loadStabilityForms = async (request: stabilityFormApi.ListStabilityFormsRequest) => {
        this.progressFlags.loadingStabilityForms = true;

        try {
            const response = await services.StabilityForms.listStabilityForms(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.stabilityForms = response.data.items;
            this.totalPages = response.data.totalPages;
            this.totalCount = response.data.totalCount;

            if (this.currentPage > response.data.totalPages && response.data.totalPages !== 0) {
                this.currentPage = response.data.totalPages;
            }
        } finally {
            this.progressFlags.loadingStabilityForms = false;
        }
    };

    loadStabilityFormHistoryChanges = async (request: stabilityFormApi.GetStabilityFormChangesHistoryRequest) => {
        this.progressFlags.loadingStabilityFormHistoryChanges = true;

        try {
            const response = await services.StabilityForms.getStabilityFormChangesHistory(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.stabilityFormChangesHistory = response.data.items;
            this.totalHistoryPages = response.data.totalPages;

            if (this.currentHistoryPage > response.data.totalPages && response.data.totalPages !== 0) {
                this.currentHistoryPage = response.data.totalPages;
            }
        } finally {
            this.progressFlags.loadingStabilityFormHistoryChanges = false;
        }
    };

    createStabilityForm = async (
        customerId: string,
        form: stabilityFormAndProductModels.StabilityFormModel,
        authModel: AuthModel,
        userMessage: string,
        callback: (input: number, errors: ApiValidationError[]) => void
    ) => {
        let response;
        this.progressFlags.loadingReAuthentication = true;
        const request: stabilityFormApi.CreateStabilityFormRequest = this.generateCreateRequest(
            form,
            authModel,
            userMessage
        );

        try {
            response = await services.StabilityForms.createStabilityForm(customerId, request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.formDetails.id = response.data.id;
        } finally {
            this.progressFlags.loadingReAuthentication = false;

            callback(
                response ? response.status : 0,
                (response?.data as unknown as BaseResponse)?.validationErrors ?? []
            );
        }
    };

    getStabilityFormDetails = async (customerId: string, stabilityFormId: string) => {
        this.progressFlags.loadingStabilityForm = true;

        try {
            const response = await services.StabilityForms.getStabilityFormDetails(customerId, stabilityFormId);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.formDetails = this.setStabilityFormData(response.data);
        } finally {
            this.progressFlags.loadingStabilityForm = false;
        }
    };

    getStabilityFormForEditing = async (customerId: string, stabilityFormId: string) => {
        this.progressFlags.loadingStabilityForm = true;

        try {
            const response = await services.StabilityForms.getStabilityFormForEditing(customerId, stabilityFormId);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.formDetails = this.setStabilityFormData(response.data);
        } finally {
            this.progressFlags.loadingStabilityForm = false;
        }
    };

    editStabilityForm = async (
        customerId: string,
        stabilityFormId: string,
        form: stabilityFormAndProductModels.StabilityFormModel,
        authModel: AuthModel,
        userMessage: string,
        callback: (input: number, errors: ApiValidationError[]) => void
    ) => {
        let response;
        this.progressFlags.loadingReAuthentication = true;
        const request: stabilityFormApi.EditStabilityFormRequest = this.generateEditRequest(
            form,
            authModel,
            userMessage
        );

        try {
            response = await services.StabilityForms.editStabilityForm(customerId, stabilityFormId, request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.formDetails.id = response.data.id;
        } finally {
            this.progressFlags.loadingReAuthentication = false;

            callback(
                response ? response.status : 0,
                (response?.data as unknown as BaseResponse)?.validationErrors ?? []
            );
        }
    };

    resetStabilityFormData = () => {
        this.formDetails = {
            id: "",
            number: "",
            version: "",
            effectiveDate: "",
            customerId: "",
            ownerEmail: "",
            ownerFirstName: "",
            ownerLastName: "",
            product: {
                id: "",
                displayId: "",
                name: "",
                genericName: "",
                storageConditionFrom: "",
                rangeOperatorFrom: stabilityFormAndProductModels.RangeOperatorFrom.GREATER_THAN_OR_EQUAL,
                storageConditionTo: "",
                rangeOperatorTo: stabilityFormAndProductModels.RangeOperatorTo.LESS_THAN_OR_EQUAL,
                SKUs: [],
                storageTypeId: "",
                storageType: "",
                presentationId: "",
                presentation: "",
                categoryId: "",
                category: "",
                expiryPeriodInHours: "",
                productSpec: "",
                freezeCycleLimit: "",
                heatCycleLimit: "",
                representations: [
                    {
                        id: "",
                        dosage: "",
                        unitOfMeasureId: "",
                        unitOfMeasure: "",
                        doseFormId: "",
                        doseForm: "",
                        allDosagesFlag: "false",
                    },
                ],
            },
            temperatureRanges: [
                {
                    id: "",
                    lowerLimit: "",
                    rangeOperatorFrom: stabilityFormAndProductModels.RangeOperatorFrom.GREATER_THAN_OR_EQUAL,
                    upperLimit: "",
                    rangeOperatorTo: stabilityFormAndProductModels.RangeOperatorTo.LESS_THAN_OR_EQUAL,
                    references: "",
                    totalStabilityBudget: "",
                    remainingStabilityBudget: "",
                    medicalInfo: "",
                    isReferenced: false,
                    rangeRegions: [],
                },
            ],
        };

        this.formErrors = {};
    };

    setStabilityFormData = (
        form: stabilityFormApi.StabilityFormResponse
    ): stabilityFormAndProductModels.StabilityFormDefinition => {
        return {
            id: form.id,
            number: form.number ?? "",
            version: form.version ?? "",
            customerId: form.customerId,
            effectiveDate: form.effectiveDate ? dateToFormat(form.effectiveDate, commonConstants.shortDateFormat) : "",
            ownerEmail: form.ownerEmail ?? "",
            ownerFirstName: form.ownerFirstName ?? "",
            ownerLastName: form.ownerLastName ?? "",
            product: {
                id: form.product.id,
                displayId: form.product.displayId ?? "",
                name: form.product.name ?? "",
                genericName: form.product.genericName ?? "",
                storageConditionFrom: form.product.storageConditionFrom?.toString() ?? "",
                rangeOperatorFrom: form.product.rangeOperatorFrom,
                storageConditionTo: form.product.storageConditionTo?.toString() ?? "",
                rangeOperatorTo: form.product.rangeOperatorTo,
                SKUs: [...form.product.SKUs],
                storageTypeId: form.product.storageTypeId ?? "",
                storageType: form.product.storageType ?? "",
                presentationId: form.product.presentationId ?? "",
                presentation: form.product.presentation ?? "",
                categoryId: form.product.categoryId ?? "",
                category: form.product.category ?? "",
                expiryPeriodInHours: form.product.expiryPeriodInHours?.toString() ?? "",
                productSpec: form.product.productSpec?.toString() ?? "",
                freezeCycleLimit: form.product.freezeCycleLimit?.toString() ?? "",
                heatCycleLimit: form.product.heatCycleLimit?.toString() ?? "",
                representations: form.product.representations.map((x) => ({
                    id: x.id,
                    dosage: x.dosage?.toString() ?? "",
                    unitOfMeasureId: x.unitOfMeasureId ?? "",
                    unitOfMeasure: x.unitOfMeasure ?? "",
                    doseFormId: x.doseFormId ?? "",
                    doseForm: x.doseForm ?? "",
                    allDosagesFlag: x.allDosagesFlag ? "true" : "false",
                })),
            },
            temperatureRanges: form.temperatureRanges.map((x) => ({
                id: x.id,
                lowerLimit: x.lowerLimit?.toString() ?? "",
                upperLimit: x.upperLimit?.toString() ?? "",
                rangeOperatorFrom: x.rangeOperatorFrom,
                rangeOperatorTo: x.rangeOperatorTo,
                references: x.references ?? "",
                remainingStabilityBudget: x.remainingStabilityBudget?.toString() ?? "",
                totalStabilityBudget: x.totalStabilityBudget?.toString() ?? "",
                medicalInfo: x.medicalInfo?.toString() ?? "",
                isReferenced: x.isReferenced,
                rangeRegions: x.rangeRegions.map((x) => ({
                    id: x.id ?? "",
                    regionId: x.regionId ?? allCountriesAndRegions,
                    regionName: x.regionName ?? "",
                    medicalInfo: x.medicalInfo?.toString() ?? "",
                    flows: x.flows.map((x) => ({
                        id: x.id,
                        name: x.name ?? "",
                        remainingStabilityBudget: x.remainingStabilityBudget?.toString() ?? "",
                        plannedDeduction: x.plannedDeduction?.toString() ?? "",
                        references: x.references ?? "",
                        steps: x.steps ?? "",
                        isReferenced: x.isReferenced,
                    })),
                })),
            })),
        };
    };

    generateCreateRequest = (
        values: stabilityFormAndProductModels.StabilityFormModel,
        authModel: AuthModel,
        userMessage: string
    ): stabilityFormApi.CreateStabilityFormRequest => {
        return {
            number: correctStringValueForCreateRequest(values.number),
            version: correctStringValueForCreateRequest(values.version),
            ownerEmail: correctStringValueForCreateRequest(values.ownerEmail),
            effectiveDate: values.effectiveDate.trim(),
            authModel: authModel,
            userMessage: userMessage,
            product: {
                displayId: values.product.displayId.trim(),
                name: values.product.name.trim(),
                genericName: correctStringValueForCreateRequest(values.product.genericName),
                storageConditionFrom: correctStringValueForCreateRequest(values.product.storageConditionFrom),
                rangeOperatorFrom: values.product.rangeOperatorFrom,
                storageConditionTo: correctStringValueForCreateRequest(values.product.storageConditionTo),
                rangeOperatorTo: values.product.rangeOperatorTo,
                categoryId: correctStringValueForCreateRequest(values.product.categoryId),
                presentationId: correctStringValueForCreateRequest(values.product.presentationId),
                storageTypeId: values.product.storageTypeId,
                SKUs: values.product.SKUs,
                expiryPeriodInHours: correctStringValueForCreateRequest(values.product.expiryPeriodInHours),
                productSpec: correctStringValueForCreateRequest(values.product.productSpec),
                freezeCycleLimit: correctStringValueForCreateRequest(values.product.freezeCycleLimit),
                heatCycleLimit: correctStringValueForCreateRequest(values.product.heatCycleLimit),
                representations: values.product.representations.map((x, i) =>
                    this.generateProductRepresentationForCreateRequest(x, i)
                ),
            },
            temperatureRanges: values.temperatureRanges.map((x, i) =>
                this.generateTemperatureRangeForCreateRequest(x, i)
            ),
        };
    };

    generateEditRequest = (
        values: stabilityFormAndProductModels.StabilityFormModel,
        authModel: AuthModel,
        userMessage: string
    ): stabilityFormApi.EditStabilityFormRequest => {
        const product = values.product;

        const editRequest: stabilityFormApi.EditStabilityFormRequest = {
            number: correctStringValueForEditRequest(values.number, this.formDetails.number),
            version: correctStringValueForEditRequest(values.version, this.formDetails.version),
            ownerEmail: correctStringValueForEditRequest(values.ownerEmail, this.formDetails.ownerEmail),
            effectiveDate:
                dateToFormat(values.effectiveDate, commonConstants.shortDateFormat) ===
                dateToFormat(this.formDetails.effectiveDate, commonConstants.shortDateFormat)
                    ? undefined
                    : values.effectiveDate,
            authModel: authModel,
            userMessage: userMessage,
            product: {
                displayId: correctStringValueForEditRequest(product.displayId, this.formDetails.product.displayId),
                name: correctStringValueForEditRequest(product.name, this.formDetails.product.name),
                genericName: correctStringValueForEditRequest(
                    product.genericName,
                    this.formDetails.product.genericName
                ),
                categoryId: correctStringValueForEditRequest(product.categoryId, this.formDetails.product.categoryId),
                storageTypeId: correctStringValueForEditRequest(
                    product.storageTypeId,
                    this.formDetails.product.storageTypeId
                ),
                presentationId: correctStringValueForEditRequest(
                    product.presentationId,
                    this.formDetails.product.presentationId
                ),
                storageConditionFrom: correctStringValueForEditRequest(
                    product.storageConditionFrom,
                    this.formDetails.product.storageConditionFrom
                ),
                rangeOperatorFrom:
                    product.rangeOperatorFrom === this.formDetails.product.rangeOperatorFrom
                        ? undefined
                        : product.rangeOperatorFrom,
                storageConditionTo: correctStringValueForEditRequest(
                    product.storageConditionTo,
                    this.formDetails.product.storageConditionTo
                ),
                rangeOperatorTo:
                    product.rangeOperatorTo === this.formDetails.product.rangeOperatorTo
                        ? undefined
                        : product.rangeOperatorTo,
                SKUs:
                    JSON.stringify(product.SKUs) === JSON.stringify(this.formDetails.product.SKUs)
                        ? undefined
                        : product.SKUs,
                expiryPeriodInHours: correctStringValueForEditRequest(
                    product.expiryPeriodInHours,
                    this.formDetails.product.expiryPeriodInHours
                ),
                productSpec: correctStringValueForEditRequest(
                    product.productSpec,
                    this.formDetails.product.productSpec
                ),
                freezeCycleLimit: correctStringValueForEditRequest(
                    product.freezeCycleLimit,
                    this.formDetails.product.freezeCycleLimit
                ),
                heatCycleLimit: correctStringValueForEditRequest(
                    product.heatCycleLimit,
                    this.formDetails.product.heatCycleLimit
                ),
                ...this.generateProductRepresentationForEditRequest(product.representations),
            },
            ...this.generateTemperatureRangesForEditRequest(values),
        };

        if (Object.values(editRequest.product!).every((x) => x === undefined)) {
            editRequest.product = undefined;
        }

        return editRequest;
    };

    generateProductRepresentationForEditRequest = (
        values: stabilityFormAndProductModels.ProductRepresentationModel[]
    ) => {
        const representationsEdit: stabilityFormApi.EditProductRepresentationRequest[] = [];
        const representationsAdd: stabilityFormApi.CreateProductRepresentationRequest[] = [];
        const representationsDelete: string[] = [];

        this.formDetails.product.representations.forEach((oldValue) => {
            const newValueIndex = values.findIndex((y) => y.id === oldValue.id);

            // for deleted representations
            if (newValueIndex === -1) {
                representationsDelete.push(oldValue.id);
                return;
            }

            const newValue = values[newValueIndex];

            // for edited representations
            const correctedRepresentation = {
                dosage: correctStringValueForEditRequest(newValue.dosage, oldValue.dosage),
                unitOfMeasureId: correctStringValueForEditRequest(newValue.unitOfMeasureId, oldValue.unitOfMeasureId),
                doseFormId: correctStringValueForEditRequest(newValue.doseFormId, oldValue.doseFormId),
                allDosagesFlag: correctStringValueForEditRequest(newValue.allDosagesFlag, oldValue.allDosagesFlag),
            };

            if (!Object.values(correctedRepresentation).every((x) => x === undefined)) {
                representationsEdit.push({
                    id: oldValue.id,
                    position: newValueIndex,
                    ...correctedRepresentation,
                    allDosagesFlag:
                        correctedRepresentation.allDosagesFlag === undefined
                            ? undefined
                            : correctedRepresentation.allDosagesFlag === "true",
                });
            }
        });

        // for new added representations
        values.forEach(
            (x, i) =>
                x.id.trim() === "" && representationsAdd.push(this.generateProductRepresentationForCreateRequest(x, i))
        );

        return { representationsEdit, representationsAdd, representationsDelete };
    };

    generateTemperatureRangesForEditRequest = (values: stabilityFormAndProductModels.StabilityFormModel) => {
        const temperatureRangesEdit: stabilityFormApi.EditTemperatureRangeRequest[] = [];
        const temperatureRangesAdd: stabilityFormApi.CreateTemperatureRangeRequest[] = [];
        const temperatureRangesDelete: string[] = [];

        this.formDetails.temperatureRanges.forEach((oldValue) => {
            const newValueIndex = values.temperatureRanges.findIndex((y) => y.id === oldValue.id);

            // for deleted ranges
            if (newValueIndex === -1) {
                temperatureRangesDelete.push(oldValue.id);
                return;
            }

            const newValue = values.temperatureRanges[newValueIndex];

            // for edited ranges
            const correctedRange = {
                lowerLimit: correctStringValueForEditRequest(newValue.lowerLimit, oldValue.lowerLimit),
                upperLimit: correctStringValueForEditRequest(newValue.upperLimit, oldValue.upperLimit),
                rangeOperatorFrom:
                    oldValue.rangeOperatorFrom === newValue.rangeOperatorFrom ? undefined : newValue.rangeOperatorFrom,
                rangeOperatorTo:
                    oldValue.rangeOperatorTo === newValue.rangeOperatorTo ? undefined : newValue.rangeOperatorTo,
                references: correctStringValueForEditRequest(newValue.references, oldValue.references),
                totalStabilityBudget: correctStringValueForEditRequest(
                    newValue.totalStabilityBudget,
                    oldValue.totalStabilityBudget
                ),
                remainingStabilityBudget: correctStringValueForEditRequest(
                    newValue.remainingStabilityBudget,
                    oldValue.remainingStabilityBudget
                ),
                medicalInfo: correctStringValueForEditRequest(newValue.medicalInfo, oldValue.medicalInfo),
            };

            const rangeRegions = this.generateRangeRegionsForEditRequest(newValue, oldValue);

            if (
                !Object.values(correctedRange).every((x) => x === undefined) ||
                Object.values(rangeRegions).some((x) => x.length > 0)
            ) {
                temperatureRangesEdit.push({
                    id: oldValue.id,
                    position: newValueIndex,
                    ...correctedRange,
                    ...rangeRegions,
                });
            }
        });

        // for new added ranges
        values.temperatureRanges.forEach(
            (x, i) =>
                x.id.trim() === "" && temperatureRangesAdd.push(this.generateTemperatureRangeForCreateRequest(x, i))
        );

        return { temperatureRangesEdit, temperatureRangesAdd, temperatureRangesDelete };
    };

    generateRangeRegionsForEditRequest(
        rangeModel: stabilityFormAndProductModels.TemperatureRangeModel,
        oldValue: stabilityFormAndProductModels.TemperatureRangeDefinition
    ) {
        const rangeRegionsEdit: stabilityFormApi.EditTemperatureRangeRegionRequest[] = [];
        const rangeRegionsAdd: stabilityFormApi.CreateTemperatureRangeRegionRequest[] = [];
        const rangeRegionsDelete: string[] = [];

        oldValue.rangeRegions.forEach((oldValue) => {
            const newValueIndex = rangeModel.rangeRegions.findIndex((x) => x.id === oldValue.id);

            // for deleted range regions
            if (newValueIndex === -1) {
                rangeRegionsDelete.push(oldValue.id);
                return;
            }

            const newValue = rangeModel.rangeRegions[newValueIndex];

            // for edited range regions
            const newRegionId = correctStringValueForEditRequest(newValue.regionId, oldValue.regionId);
            const newMedicalInfo = correctStringValueForEditRequest(newValue.medicalInfo, oldValue.medicalInfo);
            const flows = this.generateFlowsForEditRequest(newValue, oldValue);

            if (
                newRegionId !== undefined ||
                newMedicalInfo !== undefined ||
                Object.values(flows).some((x) => x.length > 0)
            ) {
                rangeRegionsEdit.push({
                    id: oldValue.id,
                    regionId: newRegionId === allCountriesAndRegions ? "" : newRegionId,
                    medicalInfo: newMedicalInfo,
                    position: newValueIndex,
                    ...flows,
                });
            }
        });

        // for new added range regions
        rangeModel.rangeRegions.forEach(
            (x, i) => x.id === "" && rangeRegionsAdd.push(this.generateRangeRegionsForCreateRequest(x, i))
        );

        return { rangeRegionsEdit, rangeRegionsAdd, rangeRegionsDelete };
    }

    generateFlowsForEditRequest(
        rangeRegionModel: stabilityFormAndProductModels.TemperatureRangeRegionModel,
        oldValue: stabilityFormAndProductModels.TemperatureRangeRegionDefinition
    ) {
        const flowsEdit: stabilityFormApi.EditFlowRequest[] = [];
        const flowsAdd: stabilityFormApi.CreateFlowRequest[] = [];
        const flowsDelete: string[] = [];

        oldValue.flows.forEach((oldValue) => {
            const newValueIndex = rangeRegionModel.flows.findIndex((x) => x.id === oldValue.id);

            // for deleted flows
            if (newValueIndex === -1) {
                flowsDelete.push(oldValue.id);
                return;
            }

            const newValue = rangeRegionModel.flows[newValueIndex];

            // for edited flows
            const correctedFlow = {
                name: correctStringValueForEditRequest(newValue.name, oldValue.name),
                steps: correctStringValueForEditRequest(newValue.steps, oldValue.steps),
                references: correctStringValueForEditRequest(newValue.references, oldValue.references),
                plannedDeduction: correctStringValueForEditRequest(
                    newValue.plannedDeduction,
                    oldValue.plannedDeduction
                ),
                remainingStabilityBudget: correctStringValueForEditRequest(
                    newValue.remainingStabilityBudget,
                    oldValue.remainingStabilityBudget
                ),
            };

            if (!Object.values(correctedFlow).every((x) => x === undefined)) {
                flowsEdit.push({ id: oldValue.id, position: newValueIndex, ...correctedFlow });
            }
        });

        // for new added flows
        rangeRegionModel.flows.forEach(
            (x, i) => x.id.trim() === "" && flowsAdd.push(this.generateFlowForCreateRequest(x, i))
        );

        return { flowsEdit, flowsAdd, flowsDelete };
    }

    generateProductRepresentationForCreateRequest = (
        representation: stabilityFormAndProductModels.ProductRepresentationModel,
        index: number
    ): stabilityFormApi.CreateProductRepresentationRequest => {
        return {
            dosage: correctStringValueForCreateRequest(representation.dosage),
            unitOfMeasureId: correctStringValueForCreateRequest(representation.unitOfMeasureId),
            doseFormId: representation.doseFormId,
            allDosagesFlag: representation.allDosagesFlag === "true",
            position: index,
        };
    };

    generateTemperatureRangeForCreateRequest = (
        range: stabilityFormAndProductModels.TemperatureRangeModel,
        index: number
    ): stabilityFormApi.CreateTemperatureRangeRequest => {
        return {
            lowerLimit: correctStringValueForCreateRequest(range.lowerLimit),
            upperLimit: correctStringValueForCreateRequest(range.upperLimit),
            rangeOperatorFrom: range.rangeOperatorFrom,
            rangeOperatorTo: range.rangeOperatorTo,
            references: correctStringValueForCreateRequest(range.references),
            totalStabilityBudget: correctStringValueForCreateRequest(range.totalStabilityBudget),
            remainingStabilityBudget: correctStringValueForCreateRequest(range.remainingStabilityBudget),
            medicalInfo: correctStringValueForCreateRequest(range.medicalInfo),
            position: index,
            rangeRegions: range.rangeRegions.map((x, i) => this.generateRangeRegionsForCreateRequest(x, i)),
        };
    };

    generateRangeRegionsForCreateRequest = (
        rangeRegion: stabilityFormAndProductModels.TemperatureRangeRegionModel,
        index: number
    ): stabilityFormApi.CreateTemperatureRangeRegionRequest => {
        return {
            regionId: rangeRegion.regionId.trim() === allCountriesAndRegions ? undefined : rangeRegion.regionId,
            medicalInfo: correctStringValueForCreateRequest(rangeRegion.medicalInfo),
            position: index,
            flows: rangeRegion.flows.map((x, i) => this.generateFlowForCreateRequest(x, i)),
        };
    };

    generateFlowForCreateRequest = (
        flow: stabilityFormAndProductModels.FlowModel,
        index: number
    ): stabilityFormApi.CreateFlowRequest => {
        return {
            name: correctStringValueForCreateRequest(flow.name),
            remainingStabilityBudget: flow.remainingStabilityBudget,
            steps: correctStringValueForCreateRequest(flow.steps),
            references: correctStringValueForCreateRequest(flow.references),
            plannedDeduction: correctStringValueForCreateRequest(flow.plannedDeduction),
            position: index,
        };
    };

    generatePdf = async (customerId: string, stabilityFormId: string) => {
        const currentTimezone = dayjs.tz.guess();

        const request: stabilityFormApi.BaseStabilityFormRequest = {
            customerId,
            stabilityFormId,
            currentTimezone,
        };

        this.progressFlags.exportingPdf = true;

        try {
            const response = await services.StabilityForms.generatePdf(request);

            if (!_.inRange(response.status, 200, 300)) return;

            const fileName = extractFileNameFromContentDisHeader(response);

            forceFileDownload(fileName, response.data);
        } finally {
            this.progressFlags.exportingPdf = false;
        }
    };
}
