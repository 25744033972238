import { ApiValidationError } from "api/models/sharedModels/ApiValidationError";
import { CustomerCsvRequest, SystemCsvRequest } from "api/models/sharedModels/CustomerLevelRequest";
import { BaseResponse, ErrorResponseWithPayload } from "api/models/sharedModels/ResponseModels";
import { PageModel } from "api/models/sharedModels/PageModel";
import * as userManagementApi from "api/models/userManagement/userManagementApi";
import { services } from "api/serviceConfig";
import { AxiosResponse } from "axios";
import { SearchParameter } from "components/LynxComponents/LynxSearch";
import dayjs from "dayjs";
import { FormikErrors } from "formik";
import { forceFileDownload } from "helpers/fileHelpers";
import { handleErrors } from "helpers/handleErrors";
import { SharedProperties } from "helpers/typeHelpers";
import _ from "lodash";
import { commonConstants } from "lynxConstants";
import { makeAutoObservable } from "mobx";
import { Page, PaginationArea } from "models/shared/Page";
import * as usersModels from "models/userManagement/userManagementModels";
import CommonStore from "./CommonStore";
import IdentityStore from "./IdentityStore";
import { localStorageService } from "helpers/localStorageService";

interface ProgressFlags {
    loadingUsers: boolean;
    loadingUsersForDropdown: boolean;
    loadingCustomersForSelection: boolean;
    loadingGroupsForSelection: boolean;
    changingUserStatus: boolean;
    editUserRequest: boolean;
    addUserRequest: boolean;
    loadingUserDetails: boolean;
    forgotPassword: boolean;
    resetPassword: boolean;
    verifyRecoveryToken: boolean;
    loadUserslistCsv: boolean;
    loadUserGuide: boolean;
    reactivatingUser: boolean;
}

export class UserStore {
    constructor(identityStore: IdentityStore, commonStore: CommonStore) {
        makeAutoObservable(this);
        this.identityStore = identityStore;
        this.commonStore = commonStore;
    }

    identityStore: IdentityStore;
    commonStore: CommonStore;

    users: usersModels.UserListItem[] = [];
    customerUsers: usersModels.UserListItem[] = [];
    totalCount: number = 0;

    customersForSelection: { id: string; name: string }[] = [];
    groupsForSelection: { id: string; name: string }[] = [];

    titleDropdownOptions: string[] = ["Mrs.", "Miss", "Ms.", "Mr.", "Dr."];

    formErrors: string[] = [];
    clearErrors = () => (this.formErrors = []);
    setErrors = (value: string[]) => (this.formErrors = value);

    selectedUser: usersModels.MarvelUser = {
        id: "",
        oktaId: "",
        firstName: "",
        lastName: "",
        title: null,
        status: usersModels.UserStatus.Active,
        groups: [],
    };

    addUserModel: usersModels.AddUserModel = {
        email: "",
        repeatEmail: "",
        firstName: "",
        lastName: "",
        title: "",
        withPassword: false,
        password: "",
        groups: [],
        customers: [],
    };

    editUserModel: usersModels.EditUserModel = {
        firstName: "",
        lastName: "",
        title: "",
        groups: [],
        customers: [],
    };

    currentPage = 1;
    totalPages = 1;
    pageSize = localStorageService.getPageSize(PaginationArea.Users) ?? 10;

    // forgot password data
    forgotPasswordErrors: FormikErrors<{ email: string }> = {};
    setForgotPasswordErrors = (errors: FormikErrors<{ email: string }>) => (this.forgotPasswordErrors = errors);

    resetPasswordErrors: FormikErrors<{ password: ""; confirmPassword: "" }> = {};
    setResetPasswordErrors = (errors: FormikErrors<{ password: ""; confirmPassword: "" }>) =>
        (this.resetPasswordErrors = errors);

    userIdForPasswordReset: string = "";
    passwordResetFlag: boolean = false;
    recoveryTokenVerificationStatus: usersModels.TokenVerificationStatus =
        usersModels.TokenVerificationStatus.Unverified;

    resetPasswordInitializedBy = {
        userId: "",
        firstName: "",
        lastName: "",
    };

    // loading indicators
    progressFlags: ProgressFlags = {
        loadingUsers: false,
        loadingUsersForDropdown: false,
        loadingCustomersForSelection: false,
        loadingGroupsForSelection: false,
        changingUserStatus: false,
        editUserRequest: false,
        addUserRequest: false,
        loadingUserDetails: false,
        forgotPassword: false,
        resetPassword: false,
        verifyRecoveryToken: false,
        loadUserslistCsv: false,
        loadUserGuide: false,
        reactivatingUser: false,
    };

    setProgressFlags = (value: Partial<ProgressFlags>) => {
        this.progressFlags = { ...this.progressFlags, ...value };
    };

    get isLastPage() {
        return this.currentPage === this.totalPages;
    }

    setPage = (page: number) => {
        this.currentPage = page;
    };

    setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    };

    clearPagination = () => {
        this.users = [];
        this.currentPage = 1;
        this.totalPages = 1;
    };

    movePage = (direction: "back" | "forward") => {
        direction === "back" ? this.currentPage-- : this.currentPage++;
    };

    setGroupsForSelection = (values: { id: string; name: string }[]) => (this.groupsForSelection = values);
    setCustomersForSelection = (values: { id: string; name: string }[]) => (this.customersForSelection = values);

    setPasswordResetFlag = (value: boolean) => {
        this.passwordResetFlag = value;
    };

    resetUserIdForPasswordReset = () => {
        this.userIdForPasswordReset = "";
    };

    resetRecoveryTokenData = () => {
        this.recoveryTokenVerificationStatus = usersModels.TokenVerificationStatus.Unverified;
        this.resetPasswordInitializedBy = {
            userId: "",
            firstName: "",
            lastName: "",
        };
    };

    forgotPassword = async (email: string, callback: (statusCode: number, errors: ApiValidationError[]) => void) => {
        this.setProgressFlags({ forgotPassword: true });

        try {
            const response = await services.Users.initializeForgotPasswordFlow(email?.trim());

            if (response.status === 202) {
                this.userIdForPasswordReset = email;
            }

            callback(response.status ?? 0, (response?.data as BaseResponse)?.validationErrors ?? []);
        } finally {
            this.setProgressFlags({ forgotPassword: false });
        }
    };

    verifyRecoveryToken = async (token: string) => {
        this.setProgressFlags({ verifyRecoveryToken: true });

        try {
            const response = await services.Users.verifyRecoveryToken(token);
            let payload;

            if (response.status === 200) {
                payload = response.data as userManagementApi.RecoveryTokenVerificationResponse;
            } else {
                payload = (
                    response.data as ErrorResponseWithPayload<userManagementApi.RecoveryTokenVerificationResponse>
                )?.payload;
            }

            if (payload) {
                this.recoveryTokenVerificationStatus = payload.verificationStatus;
                this.resetPasswordInitializedBy = {
                    userId: payload.userId ?? "",
                    firstName: payload.firstName ?? "",
                    lastName: payload.lastName ?? "",
                };
            }
        } catch (error) {
            this.recoveryTokenVerificationStatus = usersModels.TokenVerificationStatus.CannotValidate;
        } finally {
            this.setProgressFlags({ verifyRecoveryToken: false });
        }
    };

    resetPassword = async (
        token: string,
        password: string,
        callback: (statusCode: number, errors: ApiValidationError[]) => void
    ) => {
        this.setProgressFlags({ resetPassword: true });

        try {
            const response = await services.Users.resetPassword(token, password);

            if (response.status === 400) {
                const payload = (
                    response.data as ErrorResponseWithPayload<userManagementApi.RecoveryTokenVerificationResponse>
                )?.payload;

                if (payload) {
                    this.recoveryTokenVerificationStatus = payload.verificationStatus;
                    this.resetPasswordInitializedBy = {
                        userId: payload.userId ?? "",
                        firstName: payload.firstName ?? "",
                        lastName: payload.lastName ?? "",
                    };
                }
            }

            callback(response.status ?? 0, (response?.data as BaseResponse)?.validationErrors ?? []);
        } finally {
            this.setProgressFlags({ resetPassword: false });
        }
    };

    loadUsers = async (
        request?: userManagementApi.ListSystemUsersRequest | userManagementApi.ListCustomerUsersRequest
    ) => {
        this.progressFlags.loadingUsers = true;
        try {
            if (request === undefined || request?.searchValue === undefined) {
                request = { pageSize: this.pageSize, pageNumber: this.currentPage, includeGroups: true };
            }
            const response = this.identityStore.isSystemSpace
                ? await services.Users.listSystemUsers(request)
                : await services.Users.listCustomerUsers({
                      ...request,
                      customerId: this.identityStore.currentCustomer.id,
                  });

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.totalPages = response.data.totalPages;
            this.users = response.data.items;
            this.totalCount = response.data.totalCount;
        } finally {
            this.setProgressFlags({ loadingUsers: false });
        }
    };

    loadCustomersForSelection = async () => {
        if (this.progressFlags.loadingCustomersForSelection) {
            return;
        }

        this.setProgressFlags({ loadingCustomersForSelection: true });

        try {
            const response = await services.Customers.listCustomers({ pageNumber: 1, pageSize: 10000 });

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.setCustomersForSelection(response.data.items);
        } finally {
            this.setProgressFlags({ loadingCustomersForSelection: false });
        }
    };

    loadGroupsForSelection = async () => {
        if (this.progressFlags.loadingGroupsForSelection) {
            return;
        }

        this.setProgressFlags({ loadingGroupsForSelection: true });

        try {
            const request: PageModel = { pageNumber: 1, pageSize: 10000 };

            const response: AxiosResponse<
                Page<userManagementApi.SystemGroupListItemResponse | userManagementApi.CustomerGroupListItemResponse>
            > = this.identityStore.isSystemSpace
                ? await services.Groups.listSystemGroups(request)
                : await services.Groups.listCustomerGroups({
                      ...request,
                      customerId: this.identityStore.currentCustomer.id,
                  });

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.setGroupsForSelection(response.data.items);
        } finally {
            this.setProgressFlags({ loadingGroupsForSelection: false });
        }
    };

    loadCustomerUsers = async (request: userManagementApi.ListCustomerUsersRequest) => {
        this.setProgressFlags({ loadingUsersForDropdown: true });

        try {
            const response = await services.Users.listCustomerUsers(request);

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            const currentUser = response.data.items.find((x) => x.id === this.identityStore.currentUser?.id);

            // Move current user on top of the list
            if (currentUser) {
                this.customerUsers = [currentUser, ...response.data.items.filter((x) => x !== currentUser)];
            } else {
                this.customerUsers = response.data.items;
            }
        } finally {
            this.setProgressFlags({ loadingUsersForDropdown: false });
        }
    };

    changeUserStatus = async (userId: string, status: usersModels.UserStatus) => {
        this.setProgressFlags({ changingUserStatus: true });

        try {
            const request = { fieldsToEdit: ["status"], userId, status };
            let response;

            if (this.identityStore.isSystemSpace) {
                response = await services.Users.editSystemUser(request);
            } else {
                response = await services.Users.editCustomerUser({
                    ...request,
                    customerId: this.identityStore.currentCustomer.id,
                });
            }

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            const userListItem = this.users.find((x) => x.id === userId);
            userListItem!.status = status;
        } finally {
            this.setProgressFlags({ changingUserStatus: false });
        }
    };

    addUser = async (callback: (userId: string) => void) => {
        if (this.progressFlags.addUserRequest) {
            return;
        }

        const model = this.addUserModel;

        if (model.email.trim() !== model.repeatEmail.trim()) {
            this.setErrors(["Emails do not match."]);
            return;
        }

        this.setProgressFlags({ addUserRequest: true });
        this.clearErrors();

        const request: SharedProperties<
            userManagementApi.CreateSystemUserRequest,
            userManagementApi.CreateCustomerUserRequest
        > = {
            id: model.email,
            firstName: model.firstName,
            lastName: model.lastName,
            withPassword: model.withPassword,
            password: model.password,
            title: model.title === "" ? undefined : model.title,
            groups: model.groups,
        };

        try {
            const response = this.identityStore.isSystemSpace
                ? await services.Users.createSystemUser({ ...request, customers: model.customers })
                : await services.Users.createCustomerUser({
                      ...request,
                      customerId: this.identityStore.currentCustomer.id,
                  });

            if (!_.inRange(response.status, 200, 300)) {
                handleErrors(response, this.setErrors);
                return;
            }

            callback(response.data.id);
        } finally {
            this.setProgressFlags({ addUserRequest: false });
        }
    };

    loadUserDetails = async (userId: string) => {
        this.setProgressFlags({ loadingUserDetails: true });

        try {
            const response = this.identityStore.isSystemSpace
                ? await services.Users.getSystemUserDetails({ userId })
                : await services.Users.getCustomerUserDetails({
                      userId,
                      customerId: this.identityStore.currentCustomer.id,
                  });

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.selectedUser = response.data;
        } finally {
            this.setProgressFlags({ loadingUserDetails: false });
        }
    };

    getCustomerUsersListCsv = async (request: CustomerCsvRequest) => {
        this.setProgressFlags({ loadUserslistCsv: true });
        const date = dayjs().format(commonConstants.fileDateFormat);

        try {
            const response = await services.Users.getCustomerUsersListCsv(request);

            if (!_.inRange(response.status, 200, 300)) {
                return;
            }

            forceFileDownload(`Users_${date}`, response.data);
        } finally {
            this.setProgressFlags({ loadUserslistCsv: false });
        }
    };

    getSystemUsersListCsv = async (request: SystemCsvRequest) => {
        this.setProgressFlags({ loadUserslistCsv: true });
        const date = dayjs().format(commonConstants.fileDateFormat);

        try {
            const response = await services.Users.getSystemUsersListCsv(request);

            if (!_.inRange(response.status, 200, 300)) {
                return;
            }
            forceFileDownload(`Users_${date}`, response.data);
        } finally {
            this.setProgressFlags({ loadUserslistCsv: false });
        }
    };

    populateEditModel = () => {
        this.editUserModel.firstName = this.selectedUser.firstName;
        this.editUserModel.lastName = this.selectedUser.lastName;
        this.editUserModel.title = this.selectedUser.title || "";
        this.editUserModel.customers = this.selectedUser.customers?.map((x) => x.id) || [];
        this.editUserModel.groups = this.selectedUser.groups.map((x) => x.id);
    };

    editUser = async (callback: (userId: string) => void) => {
        if (this.progressFlags.editUserRequest) {
            return;
        }

        this.setProgressFlags({ editUserRequest: true });
        this.clearErrors();

        const request = this.generateEditRequest();

        try {
            const response = this.identityStore.isSystemSpace
                ? await services.Users.editSystemUser(request as userManagementApi.EditSystemUserRequest)
                : await services.Users.editCustomerUser(request as userManagementApi.EditCustomerUserRequest);

            if (!_.inRange(response.status, 200, 300)) {
                handleErrors(response, this.setErrors);
                return;
            }

            this.selectedUser = response.data;
            const userListItem = this.users.find((x) => x.id === this.selectedUser.id);

            if (userListItem) {
                userListItem.firstName = this.selectedUser.firstName;
                userListItem.lastName = this.selectedUser.lastName;
                userListItem.groups = this.selectedUser.groups;
                userListItem.title = this.selectedUser.title;
            }

            callback(response.data.id);
        } finally {
            this.setProgressFlags({ editUserRequest: false });
        }
    };

    generateEditRequest = () => {
        const fields = [
            { name: "firstName", add: this.editUserModel.firstName !== this.selectedUser.firstName },
            { name: "lastName", add: this.editUserModel.lastName !== this.selectedUser.lastName },
            { name: "title", add: this.editUserModel.title !== (this.selectedUser.title || "") },
        ];

        const fieldsToEdit = fields.filter((x) => x.add).map((x) => x.name);

        const request: SharedProperties<
            userManagementApi.EditSystemUserRequest,
            userManagementApi.EditCustomerUserRequest
        > = {
            fieldsToEdit,
            userId: this.selectedUser.id,
            firstName: this.editUserModel.firstName,
            lastName: this.editUserModel.lastName,
            title: this.editUserModel.title || null,
            addedToGroups: this.editUserModel.groups,
            removedFromGroups: this.selectedUser.groups
                .map((x) => x.id)
                .filter((x) => !this.editUserModel.groups.includes(x)),
        };

        if (this.identityStore.isSystemSpace) {
            const editSystemUserRequest: userManagementApi.EditSystemUserRequest = {
                ...request,
                addedToCustomers: this.editUserModel.customers,
                removedFromCustomers: this.selectedUser.customers
                    ?.map((x) => x.id)
                    .filter((x) => !this.editUserModel.customers.includes(x)),
            };

            return editSystemUserRequest;
        } else {
            const editCustomerUserRequest: userManagementApi.EditCustomerUserRequest = {
                ...request,
                customerId: this.identityStore.currentCustomer.id,
            };
            return editCustomerUserRequest;
        }
    };

    getUserGuidePdf = async (userGuideName: string) => {
        this.setProgressFlags({ loadUserGuide: true });

        try {
            const customerId = this.identityStore.currentCustomer.id;
            const response = await services.Users.getUserGuidePdf(userGuideName, customerId);

            if (!_.inRange(response.status, 200, 300)) return;
            return response;
        } finally {
            this.setProgressFlags({ loadUserGuide: false });
        }
    };

    clearFormState() {
        this.addUserModel = {
            firstName: "",
            lastName: "",
            email: "",
            repeatEmail: "",
            customers: [],
            groups: [],
            password: "",
            title: "",
            withPassword: false,
        };
    }

    // search
    searchValue: string = "";

    searchParameter: SearchParameter<"user">[] = ["FullName"];

    get searchInputTrimmed() {
        return this.searchValue.trim();
    }

    setSearchParameter = (value: SearchParameter<"user">[]) => {
        this.searchParameter = value;
    };

    setSearchValue = (value: string) => {
        this.searchValue = value;
    };
}
