import { Box, Drawer, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { LynxAvatar } from "components/ReusableComponents/LynxAvatar/LynxAvatar";
import ThorPagination from "components/ReusableComponents/ThorPagination/ThorPagination";
import { ModuleChip } from "components/userManagement/ModuleChip/ModuleChip";
import { displayPopupNotification } from "helpers/displayPopupNotification";
import { observer } from "mobx-react";
import { NotificationType } from "models/shared/NotificationType";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus, UserListItem, UserStatus } from "models/userManagement/userManagementModels";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useStore } from "store/StoreConfigs";
import UserActions, { UserActionOption } from "../UserActions";
import AddUserForm from "../forms/AddUserForm";
import { EditUserForm } from "../forms/EditUserForm";
import { userManagementStyles } from "./userManagementStyles";
import { LynxSearch } from "components/LynxComponents/LynxSearch/LynxSearch";
import { ListCustomerUsersRequest, ListSystemUsersRequest } from "api/models/userManagement/userManagementApi";
import { userManagementFuncs } from "../userManagementFuncs";
import FiltersOrSearchResultEmptyState from "components/ReusableComponents/FiltersComponent/FiltersResultEmptyState";
import { noSearchResultMessage } from "lynxConstants";
import dayjs from "dayjs";
import { PaginationArea } from "models/shared/Page";
import { GeneralErrorComponentWrapper } from "components/ErrorComponents/GeneralErrorPage";

export default observer(function UsersTab() {
    const classes = userManagementStyles();
    const { userStore, identityStore, permissionsStore, groupStore, commonStore } = useStore();
    const navigate = useNavigate();
    const currentTimezone = dayjs.tz.guess();

    const [showAddUserForm, setShowAddUserForm] = useState(false);
    const [showEditUserForm, setShowEditUserForm] = useState(false);

    const tableHeaders = ["Name", "Email", "User Groups", "Status"];

    const addUserSuccessCallback = (userId: string) => {
        setShowAddUserForm(false);
        const successNotification = `User '${userId}' was created successfully`;
        displayPopupNotification(NotificationType.SUCCESS, successNotification);
        userStore.clearPagination();
        userStore.loadUsers();
    };

    const editUserSuccessCallback = (userId: string) => {
        setShowEditUserForm(false);

        const successNotification = `User '${userId}' was edited successfully`;
        displayPopupNotification(NotificationType.SUCCESS, successNotification);
    };

    const editUserPermission = identityStore.isSystemSpace
        ? permissionsStore.getPermissionResult(actions.system.users.manage)
        : permissionsStore.getPermissionResult(actions.customer.users.manage, identityStore.currentCustomer.id);

    const viewUserPermission = identityStore.isSystemSpace
        ? permissionsStore.getPermissionResult(actions.system.users.view)
        : permissionsStore.getPermissionResult(actions.customer.users.view, identityStore.currentCustomer.id);

    const viewGroupPermission = identityStore.isSystemSpace
        ? permissionsStore.getPermissionResult(actions.system.groups.view)
        : permissionsStore.getPermissionResult(actions.customer.groups.view, identityStore.currentCustomer.id);

    const loadUsers = () => {
        if (userStore.searchValue) {
            userStore.setSearchParameter(["FullName", "GroupName", "Id", "Status"]);
        }

        const request: ListCustomerUsersRequest | ListSystemUsersRequest = {
            includeGroups: true,
            pageNumber: userStore.currentPage,
            pageSize: userStore.pageSize,
            searchValue: userStore.searchInputTrimmed,
            searchParameter: userStore.searchParameter,
        };
        userStore.loadUsers(request);
    };

    useEffect(() => {
        userStore.setSearchValue("");
    }, []);

    useEffect(() => {
        userStore.loadUsers();
        if (viewGroupPermission.status === MarvelPermissionStatus.Allow) {
            groupStore.loadGroups();
        }

        return () => commonStore.setShowGeneralErrorPageToFalse();
    }, [userStore.currentPage, userStore.pageSize]);

    const getUserActions = (user: UserListItem) => {
        const result: UserActionOption[] = [
            {
                optionName: "View / Edit Details",
                action: () => {
                    userStore.loadUserDetails(user.id);
                    setShowEditUserForm(true);
                },
            },
        ];

        if (
            editUserPermission.status === MarvelPermissionStatus.Allow &&
            (identityStore.isSystemSpace || !user.isSystemUser) &&
            user.id !== identityStore.currentUser.id
        ) {
            if (user.status === UserStatus.Pending) {
                result.push({
                    optionName: "Resend Invite",
                    disabled: userStore.progressFlags.reactivatingUser,
                    action: () => userManagementFuncs.reactivateUser(user.id),
                });
            }

            result.push({
                optionName: user.status === UserStatus.Deactivated ? "Activate" : "Deactivate",
                action: () =>
                    userStore.changeUserStatus(
                        user.id,
                        user.status === UserStatus.Deactivated ? UserStatus.Active : UserStatus.Deactivated
                    ),
            });
        }

        if (viewUserPermission.status === MarvelPermissionStatus.Allow) {
            result.push({
                optionName: "Audit Trail",
                action: () => navigate(`/userManagement/users/${user.id}/audit`),
                withImmediateAction: true,
            });
        }

        return result;
    };

    return (
        <GeneralErrorComponentWrapper>
            <main className={classes.tabContainer}>
                <Grid container alignItems="center" justifyContent="space-between" className={classes.container}>
                    <Grid item>
                        <LynxSearch
                            searchValue={userStore.searchValue}
                            setSearchValue={userStore.setSearchValue}
                            request={loadUsers}
                            placeholder={"Search User"}
                        />
                    </Grid>
                    <Grid item className={classes.buttonsContainer}>
                        <LynxButton
                            className={classes.actionButton}
                            variant="secondary"
                            size="medium"
                            onClick={() => {
                                identityStore.isSystemSpace
                                    ? userStore.getSystemUsersListCsv({ currentTimezone })
                                    : userStore.getCustomerUsersListCsv({
                                          customerId: identityStore.currentCustomer.id,
                                          currentTimezone,
                                      });
                            }}
                            disabled={viewUserPermission.status !== MarvelPermissionStatus.Allow}
                            loading={
                                userStore.progressFlags.loadUserslistCsv ||
                                viewUserPermission.status === MarvelPermissionStatus.Loading
                            }
                        >
                            Export to CSV
                        </LynxButton>
                        <LynxButton
                            size="medium"
                            className={classes.actionButton}
                            onClick={() => setShowAddUserForm(true)}
                            disabled={editUserPermission.status !== MarvelPermissionStatus.Allow}
                            loading={editUserPermission.status === MarvelPermissionStatus.Loading}
                        >
                            Add New User
                        </LynxButton>
                    </Grid>
                </Grid>

                {showAddUserForm && (
                    <Drawer
                        anchor="right"
                        open={showAddUserForm}
                        onClose={() => setShowAddUserForm(false)}
                        className={classes.drawer}
                    >
                        <AddUserForm successCallback={addUserSuccessCallback} />
                    </Drawer>
                )}

                {showEditUserForm && (
                    <Drawer
                        anchor="right"
                        open={showEditUserForm}
                        onClose={() => setShowEditUserForm(false)}
                        className={classes.drawer}
                    >
                        <EditUserForm successCallback={editUserSuccessCallback} />
                    </Drawer>
                )}

                {userStore.progressFlags.loadingUsers ? (
                    <FullScreenLoadingIndicator />
                ) : (
                    <>
                        {userStore.users.length === 0 ? (
                            <FiltersOrSearchResultEmptyState message={noSearchResultMessage} />
                        ) : (
                            <>
                                <Table className={classes.table}>
                                    <TableHead>
                                        {tableHeaders.map((header) => (
                                            <TableCell className={classes.headerCell} key={header}>
                                                {header}
                                            </TableCell>
                                        ))}
                                        <TableCell className={classes.headerCell} />
                                    </TableHead>
                                    <TableBody>
                                        {userStore.users.map((user) => (
                                            <React.Fragment key={user.id}>
                                                <TableRow className={classes.tableRow} key={user.id}>
                                                    <TableCell
                                                        style={{
                                                            display: "table-cell",
                                                            verticalAlign: "middle",
                                                            borderSpacing: "initial",
                                                        }}
                                                    >
                                                        <Box display="flex" alignItems="center" gridGap="0.5rem">
                                                            <LynxAvatar
                                                                firstName={user.firstName}
                                                                lastName={user.lastName}
                                                            />
                                                            <LynxTypography className={classes.userName}>
                                                                {user.firstName} {user.lastName}
                                                            </LynxTypography>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        <LynxTypography className={classes.tableText}>
                                                            {user.id}
                                                        </LynxTypography>
                                                    </TableCell>
                                                    <TableCell>
                                                        {user.groups.map((group) => (
                                                            <ModuleChip key={group.id} text={group.name} />
                                                        ))}
                                                    </TableCell>
                                                    <TableCell>
                                                        <LynxTypography className={classes.tableText}>
                                                            {user.status}
                                                        </LynxTypography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <UserActions options={getUserActions(user)} />
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>

                                {userStore.users.length > 0 && (
                                    <ThorPagination
                                        page={userStore.currentPage}
                                        pages={userStore.totalPages}
                                        onPageChange={userStore.movePage}
                                        isLastPage={userStore.isLastPage}
                                        setPage={userStore.setPage}
                                        area={PaginationArea.Users}
                                        setPageSize={userStore.setPageSize}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </main>
        </GeneralErrorComponentWrapper>
    );
});
