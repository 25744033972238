import React, { useEffect } from "react";
import { InputLabel } from "@material-ui/core";

import { observer } from "mobx-react";
import { useStore } from "store/StoreConfigs";
import { ErrorList } from "../ErrorList/ErrorList";
import { formStyles } from "./FormStyles";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { UserItem } from "../UserList/UserListProps";
import LoadingIndicator from "components/ReusableComponents/LoadingIndicator/LoadingIndicator";
import { UserList } from "../UserList/UserList";
import { LynxInput } from "components/LynxComponents/LynxInput/LynxInput";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";

interface EditCustomerFormProps {
    successCallback: (customerName: string) => void;
}

export const EditCustomerForm = observer((props: EditCustomerFormProps) => {
    const classes = formStyles();
    const { customerStore, permissionsStore } = useStore();

    const editPermission = permissionsStore.getPermissionResult(actions.system.customers.manage);
    const accessDenied = editPermission.status !== MarvelPermissionStatus.Allow;

    useEffect(() => {
        customerStore.clearErrors();

        if (editPermission.status === MarvelPermissionStatus.Allow) {
            customerStore.loadUsersForSelection();
        }
    }, []);

    useEffect(() => {
        customerStore.populateEditModel();

        if (customerStore.selectedCustomer.id && editPermission.status !== MarvelPermissionStatus.Allow) {
            customerStore.setUsersForSelection(customerStore.selectedCustomer.systemUsers);
        }
    }, [customerStore.selectedCustomer]);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        customerStore.clearErrors();
        customerStore.editCustomerModel[e.target.name as string] = e.target.value;
    };

    const handleUserSelectionChange = (newSelection: UserItem[]) => {
        customerStore.clearErrors();
        customerStore.editCustomerModel.systemUsers = newSelection;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        customerStore.editCustomer(props.successCallback);
    };

    if (customerStore.progressFlags.loadingCustomerDetails) {
        return <LoadingIndicator style={{ margin: "0 154px", width: "300px", height: "98vh" }} />;
    }

    return (
        <div className={classes.formContainer}>
            <form className={classes.form} onSubmit={handleSubmit}>
                <div className={classes.formSection}>
                    <LynxTypography className={classes.title}>Edit customer</LynxTypography>

                    <InputLabel id="edit-customer-name-label" className={classes.formInputLabel}>
                        Customer Name
                    </InputLabel>
                    <LynxInput
                        size="large"
                        name="name"
                        value={customerStore.editCustomerModel.name}
                        onChange={handleInputChange}
                        placeholder="e.g. Carrier"
                        formControlClassName={classes.input}
                        disabled={accessDenied}
                    />

                    <InputLabel id="edit-customer-email-label" className={classes.formInputLabel}>
                        Email <span className={classes.optionalFont}>(Optional)</span>
                    </InputLabel>
                    <LynxInput
                        size="large"
                        name="email"
                        value={customerStore.editCustomerModel.email}
                        onChange={handleInputChange}
                        formControlClassName={classes.input}
                        disabled={accessDenied}
                    />

                    <InputLabel id="edit-customer-address-label" className={classes.formInputLabel}>
                        Address <span className={classes.optionalFont}>(Optional)</span>
                    </InputLabel>
                    <LynxInput
                        size="large"
                        name="address"
                        value={customerStore.editCustomerModel.address}
                        onChange={handleInputChange}
                        formControlClassName={classes.input}
                        disabled={accessDenied}
                    />
                </div>

                <hr className={classes.separator} />

                <div className={classes.formSection}>
                    <LynxTypography className={classes.formInputLabel}>Assigned System Users</LynxTypography>
                    {customerStore.progressFlags.loadingUsersForSelection ? (
                        <LoadingIndicator className={classes.loadingUsersIndicator} />
                    ) : (
                        <UserList
                            users={customerStore.usersForSelection}
                            selectedUsers={customerStore.selectedCustomer.systemUsers}
                            onSelectionChange={handleUserSelectionChange}
                            disabled={accessDenied}
                        />
                    )}
                </div>

                <hr className={classes.separator} />

                <div className={classes.formSection}>
                    <ErrorList errors={customerStore.formErrors} style={{ marginBottom: "10px" }} />
                    <LynxButton
                        className={classes.submitButton}
                        disabled={
                            customerStore.progressFlags.loadingUsersForSelection ||
                            customerStore.progressFlags.editCustomerRequest ||
                            accessDenied
                        }
                        loading={
                            customerStore.progressFlags.editCustomerRequest ||
                            editPermission.status === MarvelPermissionStatus.Loading
                        }
                        type="submit"
                    >
                        Submit
                    </LynxButton>
                </div>
            </form>
        </div>
    );
});
