import { apiRoutes } from "api/apiRoutes";
import * as userManagementApi from "api/models/userManagement/userManagementApi";
import { torAxiosIgnoreErrors } from "api/serviceConfig";
import { AxiosRequestConfig } from "axios";
import { Page } from "models/shared/Page";

export class GroupsService {
    listSystemGroups = async (request: userManagementApi.ListSystemGroupsRequest) => {
        const url = apiRoutes.groups.listSystemGroups;

        const requestConfig: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                searchValue: request.searchValue,
                searchParameter: request.searchParameter?.join(","),
            },
        };

        const response = await torAxiosIgnoreErrors.get<Page<userManagementApi.SystemGroupListItemResponse>>(
            url,
            requestConfig
        );
        return response;
    };

    getSystemGroupDetails = async (request: userManagementApi.GetSystemGroupDetailsRequest) => {
        const url = apiRoutes.groups.getSystemGroupDetails(request.groupId);
        const response = await torAxiosIgnoreErrors.get<userManagementApi.GroupDetailsResponse>(url);
        return response;
    };

    createSystemGroup = async (request: userManagementApi.CreateSystemGroupRequest) => {
        const url = apiRoutes.groups.createSystemGroup;

        const body = {
            name: request.name,
            roleId: request.roleId,
            users: request.users,
            isNotify: request.isNotify,
            permissions: request.permissions,
        };

        const response = await torAxiosIgnoreErrors.post<userManagementApi.GroupDetailsResponse>(url, body);
        return response;
    };

    editSystemGroup = async (request: userManagementApi.EditSystemGroupRequest) => {
        const url = apiRoutes.groups.editSystemGroup(request.groupId);

        const body = {
            fieldsToEdit: request.fieldsToEdit,
            name: request.name,
            status: request.status,
            roleId: request.roleId,
            addedUsers: request.addedUsers,
            removedUsers: request.removedUsers,
            permissionsToCheckAndIgnoreRole: request.permissionsToCheckAndIgnoreRole,
            permissionsToUncheckAndIgnoreRole: request.permissionsToUncheckAndIgnoreRole,
            permissionsToManageByRole: request.permissionsToManageByRole,
        };

        const response = await torAxiosIgnoreErrors.patch<userManagementApi.GroupDetailsResponse>(url, body);
        return response;
    };

    listCustomerGroups = async (request: userManagementApi.ListCustomerGroupsRequest) => {
        const url = apiRoutes.groups.listCustomerGroups(request.customerId);

        const requestConfig: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                searchValue: request.searchValue,
                searchParameter: request.searchParameter?.join(","),
            },
        };

        const response = await torAxiosIgnoreErrors.get<Page<userManagementApi.CustomerGroupListItemResponse>>(
            url,
            requestConfig
        );
        return response;
    };

    getCustomerGroupDetails = async (request: userManagementApi.GetCustomerGroupDetailsRequest) => {
        const url = apiRoutes.groups.getCustomerGroupDetails(request.customerId, request.groupId);
        const response = await torAxiosIgnoreErrors.get<userManagementApi.GroupDetailsResponse>(url);
        return response;
    };

    createCustomerGroup = async (request: userManagementApi.CreateCustomerGroupRequest) => {
        const url = apiRoutes.groups.createCustomerGroup(request.customerId);

        const body = {
            name: request.name,
            roleId: request.roleId,
            users: request.users,
            isNotify: request.isNotify,
            regions: request.regions,
            permissions: request.permissions,
        };

        const response = await torAxiosIgnoreErrors.post<userManagementApi.GroupDetailsResponse>(url, body);
        return response;
    };

    editCustomerGroup = async (request: userManagementApi.EditCustomerGroupRequest) => {
        const url = apiRoutes.groups.editCustomerGroup(request.customerId, request.groupId);

        const body = {
            fieldsToEdit: request.fieldsToEdit,
            name: request.name,
            status: request.status,
            roleId: request.roleId,
            isNotify: request.isNotify,
            addedUsers: request.addedUsers,
            removedUsers: request.removedUsers,
            addedRegions: request.addedRegions,
            permissionsToCheckAndIgnoreRole: request.permissionsToCheckAndIgnoreRole,
            permissionsToUncheckAndIgnoreRole: request.permissionsToUncheckAndIgnoreRole,
            permissionsToManageByRole: request.permissionsToManageByRole,
        };

        const response = await torAxiosIgnoreErrors.patch<userManagementApi.GroupDetailsResponse>(url, body);
        return response;
    };
}
